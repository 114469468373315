import React from 'react'
import ArtistGroupRoutes from './ArtistGroupRoutes'
import ArtistRoutes from './ArtistRoutes'

const HomeComponent = React.lazy(
    () => import('../components/portal/home/PortalHome')
)

const ProfileComponent = React.lazy(
    () => import('../components/portal/profile/Profile')
)

const ContentComponent = React.lazy(
    () => import('../components/portal/content/Content')
)

const BlogDetail = React.lazy(
    () => import('../components/portal/blogs/BlogDetail')
)

const Page = React.lazy(() => import('../components/portal/pages/Page'))

const Favorites = React.lazy(
    () => import('../components/portal/favorites/Favorites')
)

const FaqIndex = React.lazy(() => import('../components/portal/faq/FaqIndex'))

const Faq = React.lazy(() => import('../components/portal/faq/Faq'))

const NewsList = React.lazy(() => import('../components/portal/news/NewsIndex'))

const CreatorList = React.lazy(
    () => import('../components/portal/creatorList/CreatorList')
)

const NewsDetail = React.lazy(
    () => import('../components/portal/news/NewsDetail')
)

const Contact = React.lazy(() => import('../components/portal/contact/Contact'))

const BannerDetail = React.lazy(
    () => import('../components/portal/home/banner/BannerDetail')
)

const FooterBannerDetail = React.lazy(
    () => import('../components/portal/home/banner/FooterBannerDetail')
)

const CallForArtists = React.lazy(
    () => import('../components/portal/callForArtists/CallForArtist')
)

const ArtistListPage = React.lazy(
    () => import('../components/portal/artistListPage/ArtistList')
)

const GuideIndex = React.lazy(
    () => import('../components/portal/guide/GuideIndex')
)

const GuideDetail = React.lazy(
    () => import('../components/portal/guide/GuideDetail')
)

const PagePreview = React.lazy(
    () => import('../components/portal/preview/PagePreview')
)

const GachaHome = React.lazy(() => import('../components/gacha/home/GachaHome'))

const GachaDetail = React.lazy(
    () => import('../components/gacha/detail/GachaDetail')
)

const ListFC = React.lazy(() => import('../components/portal/favorites/ListFC'))

const Event = React.lazy(() => import('../components/portal/event/event'))

const RankingList = React.lazy(
    () => import('../components/portal/event/rankingList')
)

const FanRankingList = React.lazy(
    () => import('../components/portal/event/fanranking')
)

const SettingCreator = React.lazy(
    () => import('../components/portal/settingCreator/SettingCreator')
)

const CreatorDetail = React.lazy(
    () => import('../components/portal/CreatorDetail')
)

export type RouteType = {
    path: string
    name: string
    prefix: string
    component: React.ComponentType
    exact?: boolean
    meta?: Record<string, any>
}

const routes: Array<RouteType> = [
    {
        path: '/',
        prefix: '/portal',
        name: 'portal-home',
        component: HomeComponent,
    },
    {
        path: '/profile',
        name: 'portal-profile',
        prefix: '/portal',
        component: ProfileComponent,
    },
    {
        path: '/content',
        name: 'portal-content',
        prefix: '/portal',
        component: ContentComponent,
    },
    {
        path: '/artist/:slug',
        name: 'portal-artist',
        prefix: '/portal',
        exact: false,
        component: ArtistRoutes,
    },

    {
        path: '/page/:slug',
        name: 'portal-pages',
        prefix: '/portal',
        exact: true,
        component: Page,
    },
    {
        path: '/page-preview/:slug',
        name: 'portal-pages',
        prefix: '/portal',
        exact: true,
        component: PagePreview,
    },
    {
        path: '/favorites',
        name: 'portal-favorites',
        prefix: '/portal',
        component: Favorites,
        meta: {
            protected: true,
        },
    },
    {
        path: '/listFC',
        name: 'portal-listFC',
        prefix: '/portal',
        component: ListFC,
        meta: {
            protected: false,
        },
    },
    {
        path: '/faq',
        name: 'portal-faq-index',
        prefix: '/portal',
        exact: true,
        component: FaqIndex,
    },
    {
        path: '/event/:event',
        name: 'portal-event',
        prefix: '/portal',
        exact: true,
        component: Event,
    },
    {
        path: '/ranking',
        name: 'portal-ranking',
        prefix: '/portal',
        exact: true,
        component: RankingList,
    },
    {
        path: '/fanRanking',
        name: 'portal-fanRanking',
        prefix: '/portal',
        exact: true,
        component: FanRankingList,
    },
    {
        path: '/faq/:id',
        name: 'portal-faqs',
        prefix: '/portal',
        exact: true,
        component: Faq,
    },
    {
        path: '/news',
        name: 'portal-news-list',
        prefix: '/portal',
        component: NewsList,
    },
    {
        path: '/creator/list',
        name: 'protal-creator-list',
        prefix: '/portal',
        component: CreatorList,
    },
    {
        path: '/news/:id',
        name: 'portal-news-detail',
        prefix: '/portal',
        component: NewsDetail,
    },
    {
        path: '/contact',
        name: 'portal-contact',
        prefix: '/portal',
        exact: true,
        component: Contact,
    },
    {
        path: '/call-for-artists',
        name: 'portal-call-for-artists',
        prefix: '/portal',
        exact: true,
        component: CallForArtists,
    },
    {
        path: '/banners/:kind/:id',
        name: 'portal-banners-detail',
        prefix: '/portal',
        exact: true,
        component: BannerDetail,
    },
    {
        path: '/footer-banners/:id',
        name: 'portal-footer-banners-detail',
        prefix: '/portal',
        exact: true,
        component: FooterBannerDetail,
    },
    {
        path: '/creator/list',
        name: 'portal-artist-list-page',
        prefix: '/portal',
        exact: true,
        component: ArtistListPage,
    },
    {
        path: '/guide/:kind',
        name: 'portal-guide-index',
        prefix: '/portal',
        exact: true,
        component: GuideIndex,
    },
    {
        path: '/guide/:kind/:id',
        name: 'portal-guide-detail',
        prefix: '/portal',
        component: GuideDetail,
    },
    {
        path: '/:slug',
        name: 'portal-artist-group',
        prefix: '/groups',
        exact: false,
        component: ArtistGroupRoutes,
    },
    {
        path: '/setting-creator',
        name: 'portal-setting-creator',
        prefix: '/portal',
        component: SettingCreator,
        meta: {
            protected: true,
        },
    },
    // Gacha group routes
    {
        path: '/',
        name: 'gacha-home',
        prefix: '/gacha',
        exact: true,
        component: GachaHome,
    },
    {
        path: '/:id',
        name: 'gacha-detail',
        prefix: '/gacha',
        exact: true,
        component: GachaDetail,
    },
]

export default routes
