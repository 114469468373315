import { Action } from 'redux'
import { FrontTheme } from '../../type/FrontTheme'

export type ThemeStateType = {
    isFetchingFrontTheme: boolean
    isFetchingGroupTheme: boolean
    frontTheme: FrontTheme
    groupTheme: FrontTheme
}

export interface ThemeActions<T = any> extends Action {
    payload?: T
}

export const ThemeActionsType = {
    fetchFrontTheme: 'THEME_FETCH_FRONT',
    fetchGroupTheme: 'THEME_FETCH_GROUP',

    setLoadingFront: 'THEME_SET_LOADING_FRONT',
    setLoadingGroup: 'THEME_SET_LOADING_GROUP',
    setFrontTheme: 'THEME_SET_FRONT',
    setGroupTheme: 'THEME_SET_GROUP',
}
