import React from 'react'
import Header from '../../../portal/Header'
import CreatorEditButton from '../CreatorEditButton'
import {
    DEFAULT_HEADER_BG_COLOR,
    DEFAULT_HEADER_COLOR,
    MODAL_EDIT_SETTING_CREATOR_HEADER,
} from '../../../../utils/constant'
import {
    COLOR_KEYS,
    CreatorSettingColorType,
} from '../../../../hook/creator/useCreatorSettingColor'
import { useModalState } from '../../../../hook/useModalState'
import ModalSettingHeader, {
    HeaderColorSetting,
} from '../../modals/listModalSettingCreator/ModalSettingHeader'
import {
    CREATOR_STATUS_KEYS,
    KeyCreatorStatusesType,
} from '../../../../hook/creator/useCreatorElementStatuses'
import HeaderMainMenu from '../../../portal/HeaderMainMenu'

type Props = {
    settingColor: CreatorSettingColorType
    isShow: boolean
    position?: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky'
    isEditable?: boolean
    isClickable?: boolean
    updateSettingColor?: (data: Partial<CreatorSettingColorType>) => void
    updateStatusElement?: (key: KeyCreatorStatusesType, value: boolean) => void
}

const CreatorHeader = (props: Props): JSX.Element | null => {
    const {
        settingColor,
        isShow,
        isEditable,
        position,
        isClickable = true,
        updateSettingColor,
        updateStatusElement,
    } = props

    const { modalState, open, close } = useModalState({
        [MODAL_EDIT_SETTING_CREATOR_HEADER]: false,
    })

    const handleOkModalSettingHeader = (
        settingsColor: HeaderColorSetting,
        isShowHeader: boolean
    ) => {
        if (updateSettingColor) {
            updateSettingColor(settingsColor)
        }
        if (updateStatusElement) {
            updateStatusElement(
                CREATOR_STATUS_KEYS.IS_SHOW_HEADER_KEY,
                isShowHeader
            )
        }
        close()
    }

    return (
        <>
            {isShow ? (
                <div className="portal-setting-creator__setting-header">
                    <Header
                        isClickable={isClickable}
                        logoStyle={{
                            color:
                                settingColor[COLOR_KEYS.COLOR_TEXT_HEADER_KEY]
                                    .hex ?? DEFAULT_HEADER_COLOR,
                        }}
                        headerStyles={{
                            position,
                            height: 'initial',
                            zIndex: 90,
                            color:
                                settingColor[COLOR_KEYS.COLOR_TEXT_HEADER_KEY]
                                    .hex ?? DEFAULT_HEADER_COLOR,
                        }}
                        headerWrapperStyles={{
                            height: 'initial',
                            backgroundColor:
                                settingColor[
                                    COLOR_KEYS.COLOR_BACKGROUND_HEADER_KEY
                                ].hex ?? DEFAULT_HEADER_BG_COLOR,
                        }}
                    />

                    {isEditable && (
                        <CreatorEditButton
                            handleEdit={() =>
                                open(MODAL_EDIT_SETTING_CREATOR_HEADER)
                            }
                            className="portal-setting-creator__edit-btn"
                        />
                    )}
                </div>
            ) : null}
            {isShow &&
                modalState?.[MODAL_EDIT_SETTING_CREATOR_HEADER] &&
                isEditable && (
                    <ModalSettingHeader
                        isModalOpen={
                            modalState?.[MODAL_EDIT_SETTING_CREATOR_HEADER]
                        }
                        isShowHeader={isShow}
                        settingColor={settingColor}
                        onOk={handleOkModalSettingHeader}
                        onCancel={close}
                    />
                )}
            <HeaderMainMenu
                isEditable={isEditable}
                isShowHeader={isShow}
                isClickable={isClickable}
                navigationStyles={
                    isEditable
                        ? {
                              position: 'initial',
                              height: 'initial',
                              zIndex: 90,
                          }
                        : {
                              top: isShow ? '66px' : 0,
                          }
                }
            />
        </>
    )
}

CreatorHeader.defaultProps = {
    isEditable: false,
    position: 'initial',
    updateSettingColor: undefined,
    updateStatusElement: undefined,
    isClickable: true,
}

export default CreatorHeader
