import { AxiosError } from 'axios'
import EcService from '../ec.service'
import { AuthEcInfoType } from '../../type/AuthType'

class AuthEcService extends EcService {
    public authInfo(authId: string) {
        return new Promise<AuthEcInfoType>((resolve, reject) => {
            this.client
                .post('authInfo', {
                    authId,
                })
                .then((response) => {
                    resolve(response.data.data as AuthEcInfoType)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }
}

export default new AuthEcService()
