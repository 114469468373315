import { AxiosResponse } from 'axios'

export const handleGetMsgError = (res: AxiosResponse): string => {
    const responseMsg = res?.data?.message

    if (typeof responseMsg === 'string') return responseMsg

    if (typeof responseMsg === 'object')
        return responseMsg[Object.keys(responseMsg)[0]]

    return 'some thing went wrong'
}

export default null
