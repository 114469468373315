import { call, put, takeLatest, all, select } from 'redux-saga/effects'
import { t } from 'i18next'
import { message } from 'antd'
import BlogService from '../../services/blog/index'
import {
    BlogActions,
    BlogActionsType,
    DeleteCommentActions,
    UpdateBlogRealTimeActions,
} from '../constant/blog.const'
import { BlogComment, BlogDetailEc, BlogType } from '../../type/BlogType'
import blogEcService from '../../services/blog/blogEc.service'
import { handleSortListComment } from '../../utils/utils'

export function* count(actions: BlogActions) {
    try {
        const blogs: number = yield call(
            BlogService.count.bind(BlogService),
            actions.payload
        )
        yield put({
            type: BlogActionsType.setCount,
            payload: blogs,
        } as BlogActions)
    } catch (e) {
        // TODO: handle error
        console.error(e)
    }
}

export function* getBlogs(actions: BlogActions) {
    try {
        yield put({
            type: BlogActionsType.setLoading,
            payload: true,
        } as BlogActions)
        const blogs: Array<BlogType> = yield call(
            BlogService.get.bind(BlogService),
            {
                ...actions.payload.queries,
                _sort: 'id:DESC',
            },
            actions.payload.artistId
        )
        yield put({
            type: BlogActionsType.setList,
            payload: blogs,
        } as BlogActions)
        yield put({
            type: BlogActionsType.setLoading,
            payload: false,
        } as BlogActions)
    } catch (e) {
        // TODO: handle error
        console.error(e)
    }
}

export function* getBlogDetail(actions: BlogActions) {
    try {
        yield put({
            type: BlogActionsType.setLoading,
            payload: true,
        } as BlogActions)

        const response: BlogDetailEc = yield call(
            blogEcService.detail.bind(blogEcService),
            actions.payload
        )

        yield put({
            type: BlogActionsType.setComments,
            payload: response.comments,
        } as BlogActions)

        yield put({
            type: BlogActionsType.setBlogEcDetail,
            payload: response.blog,
        } as BlogActions)

        yield put({
            type: BlogActionsType.setBlogOwnerId,
            payload: Number(response.blog_owner_id),
        } as BlogActions)
    } catch (e) {
        message.error(t('error.server_error'))
    } finally {
        yield put({
            type: BlogActionsType.setLoading,
            payload: false,
        } as BlogActions)
    }
}

export function* getComments(actions: BlogActions) {
    try {
        yield put({
            type: BlogActionsType.setLoading,
            payload: true,
        } as BlogActions)

        const comments: BlogComment[] = yield call(
            blogEcService.listComment.bind(blogEcService),
            actions.payload.id
        )
        yield put({
            type: BlogActionsType.setComments,
            payload: comments,
        } as BlogActions)
    } catch (e) {
        message.error(t('error.server_error'))
    } finally {
        yield put({
            type: BlogActionsType.setLoading,
            payload: false,
        } as BlogActions)
    }
}

export function* deleteComment(actions: DeleteCommentActions) {
    try {
        yield put({
            type: BlogActionsType.setLoading,
            payload: true,
        } as BlogActions)

        yield call(
            blogEcService.deleteComment.bind(blogEcService),
            actions.payload.blogId,
            actions.payload.commentId,
            actions.payload.authId
        )
    } catch (e) {
        message.error(t('error.server_error'))
    } finally {
        yield put({
            type: BlogActionsType.setLoading,
            payload: false,
        } as BlogActions)
    }
}

export function* updateListCommentRealTime(actions: UpdateBlogRealTimeActions) {
    const { flag, comment, ownerId } = actions.payload
    const currentCommentList: BlogComment[] = yield select(
        (state) => state.blog.comments
    )

    const newCommentList = handleSortListComment(
        comment,
        currentCommentList,
        ownerId,
        flag
    )

    yield put({
        type: BlogActionsType.setComments,
        payload: newCommentList,
    })
}

export function* blogSaga() {
    yield all([
        takeLatest(BlogActionsType.count, count),
        takeLatest(BlogActionsType.fetchList, getBlogs),
        takeLatest(BlogActionsType.fetchBlogComments, getComments),
        takeLatest(BlogActionsType.deleteBlogComment, deleteComment),
        takeLatest(BlogActionsType.fetchBlogEcDetail, getBlogDetail),
        takeLatest(
            BlogActionsType.updateListCommentRealtime,
            updateListCommentRealTime
        ),
    ])
}
