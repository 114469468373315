import React, { ComponentType, useEffect } from 'react'
import { message } from 'antd'
import { Helmet } from 'react-helmet'
import { useAppSelector, useAppDispatch } from '../../store/hooks/AppHooks'
import { AuthService } from '../../services/auth.service'
import { UserActionTypes } from '../../store/constant/user.const'
import { AppActionsType } from '../../store/constant/app.const'

export default function withMasterLayout<T>(
    Component: ComponentType<T>
): ComponentType<T> {
    return (props: T) => {
        const dispatch = useAppDispatch()
        const { title, description } = useAppSelector(
            (state) => state.app.settings
        )
        const isAuth = useAppSelector((state) => state.user.isAuth)
        const msgAfterLogout = useAppSelector(
            (state) => state.user.resultAfterTryLogout
        )

        if (AuthService.isAuthenticated()) {
            if (AuthService.isExpired()) {
                AuthService.removeToken()
                dispatch({ type: UserActionTypes.setAuth, payload: false })
                // window.location.reload()
                window.location.href = `${process.env.REACT_APP_EC_API_URL}/prelogout`
            } else {
                dispatch({ type: UserActionTypes.setAuth, payload: true })
            }
        }

        useEffect(() => {
            dispatch({ type: AppActionsType.fetchAll })
        }, [dispatch])

        useEffect(() => {
            if (isAuth) {
                dispatch({ type: UserActionTypes.getProfile })
            }
        }, [dispatch, isAuth])

        // process after call API 'LogoutForCms' in user saga
        useEffect(() => {
            switch (msgAfterLogout.feedback) {
                case 'failure':
                    // show message to let users know they failed to logout
                    message.error(msgAfterLogout.message)
                    break
                case 'success':
                    // remove access token from Local storage
                    AuthService.removeToken()
                    // reload the current page
                    window.location.reload()
                    break
                default:
                    return
            }
            // initiate the state of resultAfterTryLogout
            dispatch({
                type: UserActionTypes.setResultAfterTryLogout,
                payload: {
                    feedback: '',
                    message: '',
                },
            })
        }, [msgAfterLogout, dispatch])

        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{title ?? 'CyberStar portal'}</title>
                    <meta name="description" content={description} />
                    <meta name="theme-color" content="#DBF5FF" />
                </Helmet>
                <Component {...props} />
            </>
        )
    }
}
