import React from 'react'
import {
    COLOR_KEYS,
    CreatorSettingColorType,
} from '../../../hook/creator/useCreatorSettingColor'
import {
    CREATOR_PREVIEW_IMG_KEYS,
    PreviewImagesState,
} from '../../../hook/creator/useCreatorSettingPreviewImg'
import { CreatorBackgroundType, CreatorType } from '../../../type/CreatorType'
import { getBackgroundCss } from '../../../utils/creatorUtil'
import useGetWidthSize from '../../../hook/useGetWidthSize'

type Props = {
    children: React.ReactNode
    creator?: CreatorType
    settingColor?: CreatorSettingColorType
    previewImages?: PreviewImagesState
    backgroundType?: CreatorBackgroundType
    style?: React.CSSProperties
    isShowHeader?: boolean
}

const CreatorComponent = (props: Props): JSX.Element => {
    const {
        children,
        style,
        settingColor,
        backgroundType,
        previewImages,
        creator,
        isShowHeader,
    } = props
    const { width } = useGetWidthSize()

    const customStyles =
        creator && settingColor && backgroundType && previewImages
            ? {
                  color: `${
                      settingColor[COLOR_KEYS.COLOR_TEXT_GENERAL_KEY].hex
                  }`,
                  background: getBackgroundCss(
                      backgroundType,
                      settingColor?.[COLOR_KEYS.COLOR_BACKGROUND_PAGE_KEY]?.hex,
                      previewImages[
                          CREATOR_PREVIEW_IMG_KEYS.BACKGROUND_PREVIEW_KEY
                      ]?.metaData?.url ?? creator?.backgroundImage?.url
                  ),
              }
            : {}

    const paddingTop = () => {
        let paddingT = ''

        if (isShowHeader) {
            paddingT = '115px'
        } else {
            paddingT = '50px'
        }

        if (width < 768) {
            if (isShowHeader) {
                paddingT = '66px'
            } else {
                paddingT = '0'
            }
        }

        return {
            paddingTop: paddingT,
        }
    }

    return (
        <section
            className="portal-creator"
            style={{
                ...paddingTop(),
                ...customStyles,
                ...style,
            }}
        >
            <div className="portal-creator__container">{children}</div>
        </section>
    )
}

CreatorComponent.defaultProps = {
    style: {},
    creator: undefined,
    settingColor: undefined,
    previewImages: undefined,
    backgroundType: undefined,
    isShowHeader: true,
}

export default CreatorComponent
