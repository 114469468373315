import { AxiosError } from 'axios'
import { pick } from 'lodash'
import CmsService from '../cms.service'
import { Setting, MainMenu, CarouselSlide } from './type'
import { FrontTheme } from '../../type/FrontTheme'

type ThemeUrls = 'front-theme' | 'group-top-theme'

class SettingsService extends CmsService {
    public getSetting() {
        return new Promise<Setting>((resolve, reject) => {
            this.client
                .get('settings')
                .then((response) => {
                    const setting: Setting = {
                        ...response.data,
                        logo: `${CmsService.baseURL}${response.data.logo.url}`,
                        logoWhite: `${CmsService.baseURL}${response.data.logo_white.url}`,
                        mainMenus: response.data.main_menus.sort(
                            (a: MainMenu, b: MainMenu) => {
                                return a.id - b.id
                            }
                        ),
                        carousel: response.data.carousel.map(
                            (item: CarouselSlide) => {
                                return {
                                    ...item,
                                    url: `${CmsService.baseURL}${item.url}`,
                                }
                            }
                        ),
                        noAvailableLiveTicketImage: `${response.data.no_image_ticket_category.url}`,
                        noAvailableProductImage: `${response.data.no_selling_ec_product.url}`,
                    }
                    resolve(setting)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getTheme(url: ThemeUrls = 'front-theme') {
        return new Promise<FrontTheme>((resolve, reject) => {
            this.client
                .get(url)
                .then((response) => {
                    resolve(
                        pick<FrontTheme>(response.data, [
                            'accentColor',
                            'darkColor',
                            'grayColor',
                            'heavierColor',
                            'heavierGrayColor',
                            'heavyGrayColor',
                            'lightGrayColor',
                            'lighterColor',
                            'lighterGrayColor',
                            'primaryColor',
                            'secondaryColor',
                            'highlightColor',
                        ]) as FrontTheme
                    )
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }
}

export default new SettingsService()
