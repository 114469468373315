import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreatorType } from '../../type/CreatorType'

/**
 * Handle show or hide elements in creator page
 */

export type KeyCreatorStatusesType =
    | 'isShowHeader'
    | 'isShowGallery'
    | 'isShowNews'
    | 'isShowProfile'
    | 'isShowMember'
    | 'isShowLive'
    | 'isShowPersonalTalk'
    | 'isShowShop'
    | 'isShowNft'
    | 'isShowClub'
    | 'isShowBlog'

export const CREATOR_STATUS_KEYS = {
    IS_SHOW_HEADER_KEY: 'isShowHeader' as const,
    IS_SHOW_GALLERY_KEY: 'isShowGallery' as const,
    IS_SHOW_NEWS_KEY: 'isShowNews' as const,
    IS_SHOW_PROFILE_KEY: 'isShowProfile' as const,
    IS_SHOW_MEMBER_KEY: 'isShowMember' as const,
    IS_SHOW_LIVE_KEY: 'isShowLive' as const,
    IS_SHOW_PERSONAL_TALK_KEY: 'isShowPersonalTalk' as const,
    IS_SHOW_SHOP_KEY: 'isShowShop' as const,
    IS_SHOW_NFT_KEY: 'isShowNft' as const,
    IS_SHOW_CLUB_KEY: 'isShowClub' as const,
    IS_SHOW_BLOG_KEY: 'isShowBlog' as const,
} as const

export type CreatorElementStatus = {
    label?: string
    name: KeyCreatorStatusesType
    value: boolean
}

type CreatorElementStatusesHook = {
    elementStatuses: CreatorElementStatus[]
    updateStatusElement: (key: KeyCreatorStatusesType, value: boolean) => void
    setElementStatuses: (items: CreatorElementStatus[]) => void
    getStatusElement: (key: KeyCreatorStatusesType) => boolean
}

const useCreatorElementStatuses = (
    creator: CreatorType | null
): CreatorElementStatusesHook => {
    const { t } = useTranslation()

    const initValue = (key: KeyCreatorStatusesType) => {
        const value = creator?.[key]
        return typeof value === 'boolean' ? !!creator?.[key] : true
    }

    const initAllStatuses = [
        {
            label: t('label.common.header'),
            name: CREATOR_STATUS_KEYS.IS_SHOW_HEADER_KEY,
            value: initValue(CREATOR_STATUS_KEYS.IS_SHOW_HEADER_KEY),
        },
        {
            label: t('label.artist.gallery'),
            name: CREATOR_STATUS_KEYS.IS_SHOW_GALLERY_KEY,
            value: initValue(CREATOR_STATUS_KEYS.IS_SHOW_GALLERY_KEY),
        },
        {
            label: t('label.artist.news'),
            name: CREATOR_STATUS_KEYS.IS_SHOW_NEWS_KEY,
            value: initValue(CREATOR_STATUS_KEYS.IS_SHOW_NEWS_KEY),
        },
        {
            label: t('label.artist.profile'),
            name: CREATOR_STATUS_KEYS.IS_SHOW_PROFILE_KEY,
            value: initValue(CREATOR_STATUS_KEYS.IS_SHOW_PROFILE_KEY),
        },
        {
            label: t('label.artist.member'),
            name: CREATOR_STATUS_KEYS.IS_SHOW_MEMBER_KEY,
            value: initValue(CREATOR_STATUS_KEYS.IS_SHOW_MEMBER_KEY),
        },
        {
            label: t('label.artist.live'),
            name: CREATOR_STATUS_KEYS.IS_SHOW_LIVE_KEY,
            value: initValue(CREATOR_STATUS_KEYS.IS_SHOW_LIVE_KEY),
        },
        {
            label: t('label.artist.personal_talk'),
            name: CREATOR_STATUS_KEYS.IS_SHOW_PERSONAL_TALK_KEY,
            value: initValue(CREATOR_STATUS_KEYS.IS_SHOW_PERSONAL_TALK_KEY),
        },
        {
            label: t('label.artist.shop'),
            name: CREATOR_STATUS_KEYS.IS_SHOW_SHOP_KEY,
            value: initValue(CREATOR_STATUS_KEYS.IS_SHOW_SHOP_KEY),
        },
        {
            label: t('label.common.auction'),
            name: CREATOR_STATUS_KEYS.IS_SHOW_NFT_KEY,
            value: initValue(CREATOR_STATUS_KEYS.IS_SHOW_NFT_KEY),
        },
        {
            label: t('label.artist.club'),
            name: CREATOR_STATUS_KEYS.IS_SHOW_CLUB_KEY,
            value: initValue(CREATOR_STATUS_KEYS.IS_SHOW_CLUB_KEY),
        },
        {
            label: t('label.artist.blog'),
            name: CREATOR_STATUS_KEYS.IS_SHOW_BLOG_KEY,
            value: initValue(CREATOR_STATUS_KEYS.IS_SHOW_BLOG_KEY),
        },
    ]

    const [elementStatuses, setElementStatuses] =
        useState<CreatorElementStatus[]>(initAllStatuses)

    useEffect(() => {
        setElementStatuses(initAllStatuses)
    }, [creator])

    const updateStatusElement = (
        key: KeyCreatorStatusesType,
        value: boolean
    ) => {
        const currentObj = elementStatuses.find((status) => status.name === key)

        if (!currentObj) return

        const currentIdx = elementStatuses.findIndex(
            (status) => status.name === key
        )

        if (currentIdx === -1) return
        const newObj = {
            ...currentObj,
            value,
        }
        const newStatuses = [...elementStatuses]
        newStatuses[currentIdx] = newObj
        setElementStatuses(newStatuses)
    }

    const getStatusElement = (key: KeyCreatorStatusesType): boolean =>
        !!elementStatuses?.find((elementStatus) => elementStatus.name === key)
            ?.value

    return {
        elementStatuses,
        updateStatusElement,
        setElementStatuses,
        getStatusElement,
    }
}

export default useCreatorElementStatuses
