import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { GachaActions, GachaActionTypes } from '../constant/gacha.const'
import { GachaCmsType } from '../../type/GachaCmsType'
import { CarouselType } from '../../type/CarouselType'
import { GachaType } from '../../type/GachaType'
import GachaCmsService from '../../services/gacha/gachaCms.service'
import GachaEcService from '../../services/gacha/gachaEc.service'
import { rootState } from '../index'

export function* getCarousel() {
    try {
        yield put({
            type: GachaActionTypes.setCarouselLoading,
            payload: true,
        } as GachaActions)
        const gachaCarousel: Array<CarouselType> = yield call(
            GachaCmsService.getCarousel.bind(GachaCmsService)
        )
        yield put({
            type: GachaActionTypes.setCarousel,
            payload: gachaCarousel,
        } as GachaActions)
        yield put({
            type: GachaActionTypes.setCarouselLoading,
            payload: false,
        } as GachaActions)
    } catch (e) {
        console.error(e)
    }
}

export function* getGachasEc() {
    try {
        yield put({
            type: GachaActionTypes.setCollectionLoading,
            payload: true,
        } as GachaActions)
        const gachasCms: GachaCmsType[] = yield select(
            (state: rootState) => state.gacha.gachasCms
        )
        const gachaIds = gachasCms.map((item) => {
            return item.gacha_id
        })
        const gachasEc: GachaType[] = yield call(
            GachaEcService.getByIds.bind(GachaEcService),
            gachaIds
        )
        yield put({
            type: GachaActionTypes.setGachasEc,
            payload: gachasEc,
        } as GachaActions)
    } catch (e) {
        console.error(e)
    } finally {
        yield put({
            type: GachaActionTypes.setCollectionLoading,
            payload: false,
        } as GachaActions)
    }
}

export function* getGachasCms(action: GachaActions) {
    try {
        yield put({
            type: GachaActionTypes.setCollectionLoading,
            payload: true,
        } as GachaActions)
        yield put({
            type: GachaActionTypes.fetchCountGachas,
            payload: undefined,
        })
        const gachasCms: GachaCmsType[] = yield call(
            GachaCmsService.get.bind(GachaCmsService),
            action.payload
        )
        yield put({
            type: GachaActionTypes.setGachasCms,
            payload: gachasCms,
        } as GachaActions)
    } catch (e) {
        console.error(e)
    } finally {
        yield put({
            type: GachaActionTypes.setCollectionLoading,
            payload: false,
        } as GachaActions)
    }
}

export function* getCountGachas() {
    try {
        const count: number = yield call(
            GachaCmsService.count.bind(GachaCmsService)
        )
        yield put({
            type: GachaActionTypes.setCountGachas,
            payload: count,
        } as GachaActions)
    } catch (e) {
        console.error(e)
    }
}

export function* gachaSaga() {
    yield all([
        takeLatest(GachaActionTypes.fetchCarousel, getCarousel),
        takeLatest(GachaActionTypes.fetchGachasCms, getGachasCms),
        takeLatest(GachaActionTypes.fetchGachasEc, getGachasEc),
        takeLatest(GachaActionTypes.fetchCountGachas, getCountGachas),
    ])
}
