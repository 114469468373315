import { Action } from 'redux'

export type GlobalLoadingStateType = {
    isGlobalLoading: boolean
}

export interface GlobalLoadingActions<T = any> extends Action {
    payload?: T
}

export const GlobalLoadingTypes = {
    getGlobalLoading: 'GET_GLOBAL_LOADING',
    setGlobalLoading: 'SET_GLOBAL_LOADING',
}
