import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import Loading from '../components/portal/Loading'
import routes from './index'
import Footer from '../components/portal/Footer'
import PointGage from '../components/portal/PointGage'
import Protected from '../components/Protected'
import { useAppSelector } from '../store/hooks/AppHooks'
import BottomNav from '../components/portal/BottomNav'
import useCreatorSettingColor from '../hook/creator/useCreatorSettingColor'
import CreatorFooter from '../components/common/Creator/CreatorFooter'

export type PortalRoutesProps = {
    prefix: string
    isArtist: true
    isCreatorPage?: boolean
}

function PortalRoutes({
    prefix,
    isCreatorPage,
    isArtist,
}: PortalRoutesProps): JSX.Element {
    const isAuth = useAppSelector((state) => state.user.isAuth)
    const artistInfo = useAppSelector((state) => state.artist.artistInfo)
    const groupInfo = useAppSelector(
        (state) => state.artistGroup.currentGroupDataCms
    )
    const { settingColor } = useCreatorSettingColor(
        isArtist ? artistInfo : groupInfo
    )

    const routesRenderer = routes
        .filter(({ prefix: srcPrefix }) => {
            return srcPrefix === prefix
        })
        .map(({ path, name, component, exact, meta }) => {
            if (meta !== undefined && meta?.protected && !isAuth) {
                return (
                    <Route
                        key={name}
                        exact
                        path={`${prefix}${path}`}
                        component={Protected}
                    />
                )
            }
            return (
                <Route
                    key={name}
                    exact={exact ?? true}
                    path={`${prefix}${path}`}
                    component={component}
                />
            )
        })

    return (
        <Suspense fallback={<Loading />}>
            <Switch>{routesRenderer}</Switch>
            {/* <PointGage /> */}
            {isCreatorPage ? (
                <CreatorFooter settingColor={settingColor} />
            ) : (
                <>
                    <Footer />
                    <BottomNav />
                </>
            )}
        </Suspense>
    )
}

PortalRoutes.defaultProps = {
    isCreatorPage: false,
}

export default PortalRoutes
