import { useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import creatorService from '../../services/portal/creatorEc.service'
import {
    CreatorDetailHookType,
    CreatorEcType,
    CreatorType,
} from '../../type/CreatorType'
import { useAppDispatch, useAppSelector } from '../../store/hooks/AppHooks'
import artistCmsService from '../../services/artist/artistCms.service'
import groupCmsService from '../../services/groups/groupCms.service'
import artistService from '../../services/portal/artist.service'
import { ArtistActionTypes } from '../../store/constant/artist.const'
import { ArtistGroupActionType } from '../../store/constant/group.const'
import { DetailCategory } from '../../type/CategoryType'
import { ArtistType } from '../../type/ArtistType'

// Slug and isArtist parameters only passed through when user access artist or group detail
function useCreatorDetail(
    slug: string | null = null,
    isArtist: boolean | null = null
): CreatorDetailHookType {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const isDetailPage = slug && isArtist !== null
    const userProfile = useAppSelector((state) => state.user.me)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [creatorEcInfo, setCreatorEcInfo] = useState<CreatorEcType | null>(
        null
    )
    const [creatorCms, setCreatorCms] = useState<CreatorType | null>(null)
    const [isArtistFlg, setIsArtistFlg] = useState<boolean>(isArtist ?? false)
    const [listArtist, setListArtist] = useState<ArtistType[]>([])
    const groupsEc = useAppSelector((state) => state.artistGroup.groupsEc)
    const groupEcId = creatorCms?.ecId ? Number(creatorCms?.ecId) : null
    const groupDataFromEc = useMemo(() => {
        return (
            groupsEc.find((groupEc) => groupEcId && groupEc.id === groupEcId) ??
            null
        )
    }, [groupsEc, groupEcId])
    const creatorCategoryId = useMemo(() => {
        if (!slug) {
            const id = isArtistFlg
                ? creatorCms?.artist_id
                : groupDataFromEc?.groupCategoryId

            return id ?? null
        }

        if (isArtist) return creatorCms?.artist_id ?? null

        return groupDataFromEc?.groupCategoryId ?? 0
    }, [isArtist, creatorCms?.artist_id, groupDataFromEc, isArtistFlg])

    const [creatorCategory, setCreatorCategory] =
        useState<DetailCategory | null>(null)

    const loadData = async (creatorSlug: string, isCreatorArtist: boolean) => {
        if (isCreatorArtist) {
            const creatorArtist = await artistCmsService.getArtist(creatorSlug)
            dispatch({
                type: ArtistActionTypes.setArtistInfo,
                payload: creatorArtist,
            })
            setCreatorCms(creatorArtist)
        } else {
            const creatorGroup = await groupCmsService.getGroupBySlug(
                creatorSlug
            )
            dispatch({
                type: ArtistGroupActionType.setCurrentGroupData,
                payload: creatorGroup,
            })
            setCreatorCms(creatorGroup)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        artistService
            .getList()
            .then((data) => {
                setListArtist(data)
            })
            .catch(() => {
                message.error(t('error.server_error'))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        if (isDetailPage) {
            setIsLoading(true)
            loadData(slug, isArtist)
            setIsLoading(false)
        }
    }, [slug, isArtist])

    useEffect(() => {
        if (isEmpty(userProfile)) return

        if (userProfile.pialaAuthToken && !slug) {
            setIsLoading(true)
            creatorService
                .getCreatorEcInfo(userProfile.pialaAuthToken)
                .then((response) => {
                    setCreatorEcInfo(response)
                })
                .catch(() => {
                    message.error(t('error.server_error'))
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [userProfile?.pialaAuthToken, slug])

    useEffect(() => {
        if (creatorEcInfo?.slug) {
            setIsLoading(true)
            setIsArtistFlg(creatorEcInfo.isArtist)

            if (creatorEcInfo.isArtist) {
                artistCmsService
                    .getArtist(creatorEcInfo.slug)
                    .then((response) => {
                        setCreatorCms(response)
                        dispatch({
                            type: ArtistActionTypes.setArtistInfo,
                            payload: response,
                        })
                    })
                    .catch(() => {
                        message.error(t('error.server_error'))
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            } else {
                groupCmsService
                    .getGroupBySlug(creatorEcInfo.slug)
                    .then((response) => {
                        setCreatorCms(response)
                        dispatch({
                            type: ArtistGroupActionType.setCurrentGroupData,
                            payload: response,
                        })
                    })
                    .catch(() => {
                        message.error(t('error.server_error'))
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            }
        }
    }, [creatorEcInfo?.isArtist, creatorEcInfo?.slug])

    useEffect(() => {
        if (creatorCategoryId) {
            setIsLoading(true)
            artistService
                .getDetailCategory(creatorCategoryId)
                .then((data: DetailCategory[]) => {
                    if (data?.length) {
                        setCreatorCategory(data[0])
                    }
                })
                .catch(() => {
                    message.error(t('error.server_error'))
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [creatorCategoryId])

    return {
        creatorEcInfo,
        isLoading,
        creatorCms,
        isArtistFlg,
        creatorCategory,
        creatorCategoryId,
        listArtist,
    }
}

export default useCreatorDetail
