import { URLSearchParams } from 'url'
import EcService from '../ec.service'

class UserEcService extends EcService {
    /**
     * process logout of EC-cube & authentication server
     * @param params
     */
    public logoutForCms(parms: URLSearchParams) {
        return new Promise((resolve, reject) => {
            this.client
                .post('LogoutForCms', parms)
                .then((response) => {
                    resolve(response.data.message)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new UserEcService()
