import {
    BlogStateType,
    BlogActions,
    BlogActionsType,
} from '../constant/blog.const'

const initialState: BlogStateType = {
    isLoading: false,
    list: [],
    count: 0,
    comments: [],
    blogEcDetail: null,
    sendingStatus: null,
    blogOwnerId: 0,
}

function blogReducers(
    state = initialState,
    actions: BlogActions
): BlogStateType {
    switch (actions.type) {
        case BlogActionsType.setLoading:
            return { ...state, isLoading: actions.payload }
        case BlogActionsType.setList:
            return { ...state, list: actions.payload }
        case BlogActionsType.setCount:
            return { ...state, count: actions.payload }
        case BlogActionsType.setComments:
            return { ...state, comments: actions.payload ?? [] }
        case BlogActionsType.setSendingStatus:
            return { ...state, sendingStatus: actions.payload }
        case BlogActionsType.setBlogEcDetail:
            return { ...state, blogEcDetail: actions.payload }
        case BlogActionsType.setBlogOwnerId:
            return { ...state, blogOwnerId: actions.payload }
        default:
            return state
    }
}

export default blogReducers
