import { all, call, fork, put } from 'redux-saga/effects'
import { ProductKindType, ProductType } from '../../type/ProductType'
import ProductService, {
    ProductQueryParams,
    ProductListUrlQueryParams,
} from '../../services/product/index'
import { ProductActions, ProductActionTypes } from '../constant/product.const'

/**
 * Get list of live tickets from eccube
 */
export function* getLiveTickets() {
    try {
        // set true in isLoading
        yield put({
            type: ProductActionTypes.setLiveTicketsLoading,
            payload: true,
        } as ProductActions)

        // fetch live tickets with ProductService
        const liveTickets: ProductType[] = yield call(
            ProductService.getProducts.bind(ProductService),
            {
                sale_type: '6',
                recent_flg: '1',
                sort_column: 'venue_start_date',
                sort_way: '0',
                on_sale_flg: '1',
                live_type: '0',
            } as ProductQueryParams
        )

        // set fetched live tickets
        yield put({
            type: ProductActionTypes.setLiveTickets,
            payload: liveTickets,
        } as ProductActions)

        // set false in isLoading
        yield put({
            type: ProductActionTypes.setLiveTicketsLoading,
            payload: false,
        } as ProductActions)
    } catch (error) {
        console.error(error)
    }
}

export function* getArtistGoods() {
    try {
        // set true in isLoading
        yield put({
            type: ProductActionTypes.setArtistGoodsLoading,
            payload: true,
        } as ProductActions)

        // fetch live tickeets with ProductService
        const artistGoods: ProductType[] = yield call(
            ProductService.getProducts.bind(ProductService),
            {
                sale_type: '1',
                new_flg: '1',
            } as ProductQueryParams
        )

        // set fetched live tickets
        yield put({
            type: ProductActionTypes.setArtistGoods,
            payload: artistGoods,
        } as ProductActions)

        // set false in isLoading
        yield put({
            type: ProductActionTypes.setArtistGoodsLoading,
            payload: false,
        } as ProductActions)
    } catch (error) {
        console.error(error)
    }
}

/**
 * Get list of personal talk tickets from eccube
 */
export function* getPersonalTalkTickets() {
    try {
        // set true in isLoading
        yield put({
            type: ProductActionTypes.setPersonalTalksLoading,
            payload: true,
        } as ProductActions)

        // fetch live tickets with ProductService
        const personalTalkTickets: ProductType[] = yield call(
            ProductService.getProducts.bind(ProductService),
            {
                sale_type: '6',
                recent_flg: '1',
                sort_column: 'venue_start_date',
                sort_way: '0',
                on_sale_flg: '1',
                live_type: '1',
            } as ProductQueryParams
        )

        // set fetched live tickets
        yield put({
            type: ProductActionTypes.setPersonalTalkTickets,
            payload: personalTalkTickets,
        } as ProductActions)

        // set false in isLoading
        yield put({
            type: ProductActionTypes.setPersonalTalksLoading,
            payload: false,
        } as ProductActions)
    } catch (error) {
        console.error(error)
    }
}

export function* getRedirectUrlToListOnEccube(
    productKind: ProductKindType['kind']
) {
    try {
        const redirectUrl: string = yield call(
            ProductService.getProductListUrl.bind(ProductService),
            {
                product_kind: productKind,
            } as ProductListUrlQueryParams
        )

        switch (productKind) {
            case 'live':
                yield put({
                    type: ProductActionTypes.setRedirectUrlToLiveTickets,
                    payload: redirectUrl,
                })
                break
            case 'artist-goods':
                yield put({
                    type: ProductActionTypes.setRedirectUrlToArtistGoods,
                    payload: redirectUrl,
                })
                break
            case 'personal-talk':
                yield put({
                    type: ProductActionTypes.setRedirectUrlToPersonalTalkTickets,
                    payload: redirectUrl,
                })
                break
            case 'gacha-coin':
                yield put({
                    type: ProductActionTypes.setRedirectUrlToGachaCoin,
                    payload: redirectUrl,
                })
                break
            default:
                break
        }
    } catch (error) {
        console.error(error)
    }
}

/**
 * Product saga root
 */
export function* productSaga() {
    yield all([
        // fork(getLiveTickets),
        // fork(getArtistGoods),
        // fork(getPersonalTalkTickets),
        // fork(getRedirectUrlToListOnEccube, 'live'),
        // fork(getRedirectUrlToListOnEccube, 'artist-goods'),
        // fork(getRedirectUrlToListOnEccube, 'personal-talk'),
        // fork(getRedirectUrlToListOnEccube, 'gacha-coin'),
    ])
}
