import {
    NewsActions,
    NewsActionTypes,
    NewsStateType,
} from '../constant/news.const'

const initialState: NewsStateType = {
    isLoading: false,
    newsOnTop: [],
    newsAll: [],
    count: 0,
    pagination: {
        start: 1,
        limit: 10,
        current: 1,
    },
}

function newsReducers(
    state = initialState,
    actions: NewsActions
): NewsStateType {
    switch (actions.type) {
        case NewsActionTypes.setLoading:
            return { ...state, isLoading: actions.payload }
        case NewsActionTypes.setNewsOnTop:
            return { ...state, newsOnTop: actions.payload }
        case NewsActionTypes.setNewsAll:
            return { ...state, newsAll: actions.payload }
        case NewsActionTypes.setCount:
            return { ...state, count: actions.payload }
        case NewsActionTypes.setPagination:
            return { ...state, pagination: actions.payload }
        case NewsActionTypes.resetPagination:
            return { ...state, newsAll: [] }
        default:
            return state
    }
}

export default newsReducers
