import { MEDIA_TYPES } from '../../components/common/modals/textEditorModals/ModalUploadMedia'
import { MediaType } from '../../type/MediaType'
import EcService from '../ec.service'

class UploadFileService extends EcService {
    public uploadTemp(files: File[], isUploadImage: boolean) {
        return new Promise<MediaType[]>((resolve, reject) => {
            const formData = new FormData()
            if (!isUploadImage) {
                formData.append(MEDIA_TYPES.VIDEO, files[0])
            } else {
                files.forEach((file, index) => {
                    formData.append(`images[${index}]`, file)
                })
            }
            this.client
                .post('UploadTempMedia', formData)
                .then((response) => {
                    resolve(response?.data?.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new UploadFileService()
