import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import Loading from '../components/portal/Loading'
import Protected from '../components/Protected'
import routes from './index'
import Footer from '../components/portal/Footer'

export type GachaRoutesProps = {
    prefix: string
}

function GachaRoutes({ prefix }: GachaRoutesProps): JSX.Element {
    const routesRenderer = routes
        .filter(({ prefix: srcPrefix }) => {
            return srcPrefix === prefix
        })
        .map(({ path, name, component, exact, meta }) => {
            if (meta !== undefined && meta?.protected) {
                return (
                    <Route
                        key={name}
                        exact
                        path={`${prefix}${path}`}
                        component={Protected}
                    />
                )
            }
            return (
                <Route
                    key={name}
                    exact={exact ?? true}
                    path={`${prefix}${path}`}
                    component={component}
                />
            )
        })
    return (
        <Suspense fallback={<Loading />}>
            <Switch>{routesRenderer}</Switch>
            <Footer />
        </Suspense>
    )
}

export default GachaRoutes
