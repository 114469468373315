/* eslint-disable camelcase */
import { MediaType } from './MediaType'

export type BlogType = {
    // eslint-disable-next-line camelcase
    fan_club_rank_1: boolean
    // eslint-disable-next-line camelcase
    fan_club_rank_2: boolean
    // eslint-disable-next-line camelcase
    fan_club_rank_3: boolean
    id: string
    content: string
    title: string
    thumbnail: MediaType
    video: MediaType
    created_at: string
    updated_at: string
    url: string
    isPrevented: boolean
    artist_id: number
    artist_shop_id: number
    artist_group_id: number
    is_group: boolean
    isAllowComment: boolean
    commentOption: CommentOption
    blog_comments: BlogComment[]
    likes: []
}

export enum CommentOption {
    ONLY_CREATOR_CAN_REPLY = 'only_creator_can_reply',
    ALL_CAN_REPLY = 'all_can_reply',
    ONLY_COMMENT = 'only_comment',
}

export type BlogDetailEc = {
    blog: BlogType
    comments: BlogComment[]
    blog_owner_id: string
}

export type BlogComment = {
    id: number
    parent_id: number | null
    customer_id: number
    content: string
    nickname: string
    created_at: string
    comments: BlogComment[]
    sending_status: BlogCommentSendingStatus
    is_owner: BlogOwner
    is_new_comment: boolean
}

export type BlogLike = {
    id: number
    customer_id: number
    blog: BlogType
}

export enum BlogCommentSendingStatus {
    SENDING = 'SENDING',
    SENT = 'SENT',
    FAILED = 'FAILED',
}

export enum BlogOwner {
    IS_OWER = 1,
    IS_NOT_OWNER = 0,
}

export enum BlogCommentFlag {
    CREATED = 'CREATED',
    DELETED = 'DELETED',
}
export type ListBlogCommentRequest = {
    auth_id: string
    id: number
}

export type CreateBlogCommentRequest = {
    auth_id: string
    content: string
    parent_id?: number
}
