import React from 'react'
import { InputNumber as AntdInputNumber, InputNumberProps } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import { valueType } from 'antd/lib/statistic/utils'

type Props = {
    label: string
    name?: string
    value?: number
    className?: string
    onChange?: (value: number) => void
} & InputNumberProps

const InputNumber = (props: Props): JSX.Element => {
    const { label, name, className, placeholder, value, onChange, ...rest } =
        props
    const { control } = useFormContext() ?? {}

    return (
        <div className={className}>
            <p>{label}</p>
            {control && name ? (
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <AntdInputNumber
                            {...field}
                            onChange={(val: valueType) => {
                                if (onChange) {
                                    onChange(val)
                                }

                                field.onChange(val)
                            }}
                            {...rest}
                        />
                    )}
                />
            ) : (
                <AntdInputNumber name={name} value={value} {...rest} />
            )}
        </div>
    )
}

InputNumber.defaultProps = {
    value: '',
    className: '',
    name: '',
    onChange: undefined,
}

export default InputNumber
