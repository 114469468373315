import { useEffect, useState } from 'react'
import { RGBColor } from 'react-color'
import { CreatorType } from '../../type/CreatorType'
import { convertHexToRgba } from '../../utils/utils'
import {
    DEFAULT_BG_COLOR,
    DEFAULT_FOOTER_BG_COLOR,
    DEFAULT_FOOTER_COLOR,
    DEFAULT_HEADER_BG_COLOR,
    DEFAULT_HEADER_COLOR,
    DEFAULT_TEXT_COLOR,
} from '../../utils/constant'

export type ColorKeyType =
    | 'textColor'
    | 'textFooterColor'
    | 'textHeaderColor'
    | 'backgroundHeaderColor'
    | 'backgroundFooterColor'
    | 'backgroundPageColor'

export const COLOR_KEYS = {
    COLOR_TEXT_GENERAL_KEY: 'textColor' as const,
    COLOR_TEXT_FOOTER_KEY: 'textFooterColor' as const,
    COLOR_TEXT_HEADER_KEY: 'textHeaderColor' as const,
    COLOR_BACKGROUND_HEADER_KEY: 'backgroundHeaderColor' as const,
    COLOR_BACKGROUND_FOOTER_KEY: 'backgroundFooterColor' as const,
    COLOR_BACKGROUND_PAGE_KEY: 'backgroundPageColor' as const,
}

export type ColorType = {
    hex: string
    rgb: RGBColor
}

export type CreatorSettingColorType = {
    [key in ColorKeyType]: ColorType
}

type DataUpdateColorType = Partial<CreatorSettingColorType>

type CreatorSettingColorHook = {
    settingColor: CreatorSettingColorType
    updateSettingColor: (data: DataUpdateColorType) => void
}

const useCreatorSettingColor = (
    creator?: CreatorType | null
): CreatorSettingColorHook => {
    const initTextColor = creator?.textColor ?? DEFAULT_TEXT_COLOR
    const initTextFooterColor = creator?.textFooterColor ?? DEFAULT_FOOTER_COLOR
    const initTextHeaderColor = creator?.textHeaderColor ?? DEFAULT_HEADER_COLOR
    const initBackgroundHeaderColor =
        creator?.backgroundHeaderColor ?? DEFAULT_HEADER_BG_COLOR
    const initBackgroundFooterColor =
        creator?.backgroundFooterColor ?? DEFAULT_FOOTER_BG_COLOR
    const initBackgroundPageCorlor =
        creator?.backgroundPageColor ?? DEFAULT_BG_COLOR

    const defaultSettingColor = {
        [COLOR_KEYS.COLOR_TEXT_GENERAL_KEY]: {
            hex: initTextColor,
            rgb: convertHexToRgba(initTextColor),
        },
        [COLOR_KEYS.COLOR_TEXT_FOOTER_KEY]: {
            hex: initTextFooterColor,
            rgb: convertHexToRgba(initTextFooterColor),
        },
        [COLOR_KEYS.COLOR_TEXT_HEADER_KEY]: {
            hex: initTextHeaderColor,
            rgb: convertHexToRgba(initTextHeaderColor),
        },
        [COLOR_KEYS.COLOR_BACKGROUND_HEADER_KEY]: {
            hex: initBackgroundHeaderColor,
            rgb: convertHexToRgba(initBackgroundHeaderColor),
        },
        [COLOR_KEYS.COLOR_BACKGROUND_FOOTER_KEY]: {
            hex: initBackgroundFooterColor,
            rgb: convertHexToRgba(initBackgroundFooterColor),
        },
        [COLOR_KEYS.COLOR_BACKGROUND_PAGE_KEY]: {
            hex: initBackgroundPageCorlor,
            rgb: convertHexToRgba(initBackgroundPageCorlor),
        },
    }

    const [settingColor, setSettingColor] =
        useState<CreatorSettingColorType>(defaultSettingColor)

    useEffect(() => {
        setSettingColor(defaultSettingColor)
    }, [creator])

    const updateSettingColor = (data: DataUpdateColorType) => {
        setSettingColor({
            ...settingColor,
            ...data,
        })
    }

    return {
        settingColor,
        updateSettingColor,
    }
}

export default useCreatorSettingColor
