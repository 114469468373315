import { call, put, all, takeLatest } from 'redux-saga/effects'
import FaqCmsService from '../../services/faq/index'
import { FaqType, FaqCategoryType } from '../../type/FaqType'
import { FaqActionTypes, FaqActions } from '../constant/faq.const'

/**
 * Get list of categories from cms when faq list page is loaded
 */
export function* getCategories() {
    try {
        // fetch all categories
        const categories: FaqCategoryType[] = yield call(
            FaqCmsService.getFaqCategories.bind(FaqCmsService)
        )

        // set fetched categories
        yield put({
            type: FaqActionTypes.setCategories,
            payload: categories,
        } as FaqActions)

        // set empty as initial state of active category
        yield put({
            type: FaqActionTypes.setCategoryActive,
            payload: '',
        } as FaqActions)
    } catch (error) {
        console.error(error)
    }
}

/**
 * Get list faqs from cms when faq list page is loaded
 */
export function* getListFaqs() {
    try {
        // start loading faqs
        yield put({
            type: FaqActionTypes.setLoading,
            payload: true,
        } as FaqActions)

        // fetch all faqs at first
        const faqs: FaqType[] = yield call(
            FaqCmsService.getFaqs.bind(FaqCmsService)
        )

        // set fetched list
        yield put({
            type: FaqActionTypes.setList,
            payload: faqs,
        } as FaqActions)

        // set empty as initial state of searched value
        yield put({
            type: FaqActionTypes.setSearchValue,
            payload: '',
        } as FaqActions)

        // finish loading
        yield put({
            type: FaqActionTypes.setLoading,
            payload: false,
        })
    } catch (error) {
        console.log(error)
    }
}

/**
 * Artist Saga root
 */
export function* faqSaga() {
    yield all([
        takeLatest(FaqActionTypes.fetchCategory, getCategories),
        takeLatest(FaqActionTypes.fetchList, getListFaqs),
    ])
}
