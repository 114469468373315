import { CreatorEcType, SettingCreatorRequest } from '../../type/CreatorType'
import EcService from '../ec.service'

class CreatorEcService extends EcService {
    public getCreatorEcInfo(authId: string) {
        return new Promise<CreatorEcType>((resolve, reject) => {
            this.client
                .post('GetCreatorInfoLoginUser', {
                    authId,
                })
                .then((response) => {
                    resolve(response?.data?.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public updateSetting(data: SettingCreatorRequest) {
        return new Promise<CreatorEcType>((resolve, reject) => {
            this.client
                .post('StoreSettingCreator', data)
                .then((response) => {
                    resolve(response?.data?.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new CreatorEcService()
