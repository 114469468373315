import { Action } from 'redux'
import { AxiosError } from 'axios'
import {
    GroupCms,
    GroupEc,
    GroupNewsType,
    GroupTopSlide,
} from '../../type/GroupType'
import { ArtistType } from '../../type/ArtistType'
import { ProductType } from '../../type/ProductType'
import { ArtistInfoType } from '../../type/ArtistInfoType'
import { GroupFavoriteType } from '../../type/GroupFavoriteType'

export interface ArtistGroupActions<T = any> extends Action {
    payload?: T
}

export type ArtistGroupStateType = {
    isLoading: boolean
    isFetched: boolean
    currentGroupCmsId: number
    currentGroupDataCms: GroupCms
    errors: Array<AxiosError>
    news: Array<GroupNewsType>
    artistsList: Array<ArtistType>
    groupsEc: Array<GroupEc>
    topSlides: Array<GroupTopSlide>
    middleSlides: Array<GroupTopSlide>
    ecMalls: Array<ProductType>
    liveTickets: Array<ProductType>
    favoriteLoading: boolean
    favorites: Array<GroupFavoriteType>
    isFavorited: boolean
    stringSearched: string
    loadingArtistList: boolean
    artistsInfo: Array<ArtistInfoType>
    groupProductsUrl: string
    nftProduct: string
}

export const ArtistGroupActionType = {
    // action
    fetchFromCms: 'GROUP_FETCH_CMS',
    fetchNews: 'GROUP_FETCH_NEWS',
    fetchArtistsList: 'GROUP_FETCH_ARTISTS_LIST',
    fetchArtistListInfo: 'GROUP_FETCH_ARTISTS_INFO',
    fetchGroupsFromEc: 'GROUP_FETCH_GROUPS_EC',
    fetchGroupTopSlides: 'GROUP_FETCH_TOP_SLIDES',
    fetchGroupTopMiddleSlides: 'GROUP_FETCH_MIDDLE_SLIDES',
    fetchGroupEcMalls: 'GROUP_FETCH_GROUP_EC_MALL',
    fetchGroupLiveTickets: 'GROUP_FETCH_LIVE_TICKETS',
    fetchFavorites: 'GROUP_FETCH_FAVORITES',
    fetchGroupProductUrl: 'GROUP_FETCH_GROUP_PRODUCT_URL',
    addFavorite: 'GROUP_ADD_FAVORITE',
    removeFavorite: 'GROUP_REMOVE_FAVORITE',
    // set
    setLoading: 'GROUP_SET_LOADING',
    setFetched: 'GROUP_SET_FETCHED',
    setCurrentGroupCmsId: 'GROUP_SET_CURRENT_CMS_ID',
    setCurrentGroupData: 'GROUP_SET_CURRENT_CMS_DATA',
    setGroupsFromEc: 'GROUP_SET_DATA_EC',
    setArtistsList: 'GROUP_SET_ARTISTS_LIST',
    setNews: 'GROUP_SET_NEWS',
    setErrors: 'GROUP_SET_ERRORS',
    resetErrors: 'GROUP_RESET_ERRORS',
    setGroupTopSlides: 'GROUP_SET_TOP_SLIDES',
    setGroupTopMiddleSlides: 'GROUP_SET_MIDDLE_SLIDES',
    setGroupEcMalls: 'GROUP_SET_GROUP_EC_MALL',
    setGroupLiveTickets: 'GROUP_SET_LIVE_TICKETS',
    setFavorites: 'GROUP_SET_FAVORITE',
    setFavorited: 'GROUP_SET_FAVORITED',
    setFavoriteLoading: 'GROUP_SET_FAVORITE_LOADING',
    setStringSearched: 'GROUP_SET_STRING_SEARCHED',
    setLoadingArtistList: 'GROUP_SET_LOADING_ARTIST_LIST',
    setArtistsInfo: 'GROUP_SET_ARTISTS_INFO',
    setGroupProductsUrl: 'GROUP_SET_GROUP_PRODUCTS_URL',
    setNftProductGroup: 'GROUP_SET_NFT_ID',
}
