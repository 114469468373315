import React, { MouseEventHandler, useState } from 'react'
import { Drawer } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderMenuOverlay from './HeaderMenuOverlay'

type Props = {
    styleIconBar?: React.CSSProperties
}
const HeaderMenu = (props: Props): JSX.Element => {
    const { styleIconBar } = props
    const [isDropdownVisible, setIsDropdownVisible] = useState(false)
    const handleVisibleChange: () => MouseEventHandler | void = () => {
        setIsDropdownVisible(!isDropdownVisible)
    }
    const onClose = () => setIsDropdownVisible(false)
    return (
        <div>
            <button
                className="portal-groups__header__side-menu-menu__button"
                type="button"
                onClick={handleVisibleChange}
            >
                <FontAwesomeIcon
                    icon={['fas', 'bars']}
                    className="portal-groups__header__side-menu-menu__button__icon"
                    style={styleIconBar}
                />
            </button>
            <Drawer
                title={<div className="ml-16">Menu</div>}
                placement="right"
                onClose={onClose}
                width={360}
                visible={isDropdownVisible}
            >
                <HeaderMenuOverlay handleVisible={handleVisibleChange} />
            </Drawer>
        </div>
    )
}

HeaderMenu.defaultProps = {
    styleIconBar: null,
}

export default HeaderMenu
