import * as _ from 'lodash'
import {
    ArtistStateType,
    ArtistActions,
    ArtistActionTypes,
} from '../constant/artist.const'

const initialState: ArtistStateType = {
    isLoading: false,
    list: [],
    listInfo: [],
    categories: [],
    categoryActive: '',
    stringSearched: '',
    favorites: [],
    fetchingFavorites: false,
    msgAfterProcessFavorite: '',
    isFavoriteAdded: false,
    listRaw: [],
    liveTickets: [],
    listInfoRaw: [],
    pagination: {
        limit: 8,
        start: 0,
    },
    isShowFcOption: false,
    headerMenuActiveTag: 'info',
    artistInfo: null,
    isLoadingArtistInfo: false,
}

function artistReducers(
    state = initialState,
    actions: ArtistActions
): ArtistStateType {
    switch (actions.type) {
        case ArtistActionTypes.setLoading:
            return { ...state, isLoading: actions.payload }
        case ArtistActionTypes.setList:
            return { ...state, list: actions.payload }
        case ArtistActionTypes.setCategories:
            return { ...state, categories: actions.payload }
        case ArtistActionTypes.setCategoryActive:
            return { ...state, categoryActive: actions.payload }
        case ArtistActionTypes.setListArtistInfo:
            return {
                ...state,
                listInfo: _.unionBy([], actions.payload, 'id'),
                // listInfo: _.unionBy(state.listInfo, actions.payload, 'id'),
            }
        case ArtistActionTypes.setStringSearched:
            return { ...state, stringSearched: actions.payload }
        case ArtistActionTypes.setFetchingFavorites:
            return { ...state, fetchingFavorites: actions.payload }
        case ArtistActionTypes.setFavorites:
            return { ...state, favorites: actions.payload }
        case ArtistActionTypes.setMsgAfterProcessFavorite:
            return { ...state, msgAfterProcessFavorite: actions.payload }
        case ArtistActionTypes.checkFavoriteAdded:
            return {
                ...state,
                isFavoriteAdded: state.favorites.some(
                    ({ customerId, artistId }) => {
                        return (
                            customerId ===
                                actions.payload.customerId.toString() &&
                            artistId === actions.payload.artistId
                        )
                    }
                ),
            }
        case ArtistActionTypes.setListRaw:
            return { ...state, listRaw: actions.payload }
        case ArtistActionTypes.setListArtistInfoRaw:
            return { ...state, listInfoRaw: actions.payload }
        case ArtistActionTypes.setPagination:
            return { ...state, pagination: actions.payload }
        case ArtistActionTypes.resetArtistListInfo:
            return { ...state, listInfo: [] }
        case ArtistActionTypes.setIsShowFcOption:
            return { ...state, isShowFcOption: actions.payload }
        case ArtistActionTypes.setHeaderMenuActiveTag:
            return { ...state, headerMenuActiveTag: actions.payload }
        case ArtistActionTypes.setArtistLiveTickets:
            return { ...state, liveTickets: actions.payload }
        case ArtistActionTypes.setArtistInfo:
            return { ...state, artistInfo: actions.payload }
        case ArtistActionTypes.setIsLoadingArtistInfo:
            return { ...state, isLoadingArtistInfo: actions.payload }
        default:
            return state
    }
}

export default artistReducers
