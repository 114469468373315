import React, { MouseEventHandler, useState } from 'react'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import HeaderNavigation from './HeaderNavigation'
import HeaderMenu from './HeaderMenu'
import ClickableWrapper from '../common/ClickableWrapper'
import NftService from '../../services/nft.service'
import { useAppSelector } from '../../store/hooks/AppHooks'
import useGetWidthSize from '../../hook/useGetWidthSize'

type Props = {
    navigationStyles?: React.CSSProperties
    isClickable?: boolean
    isShowHeader?: boolean
    isEditable?: boolean
}
const HeaderMainMenu = (props: Props): JSX.Element => {
    const {
        navigationStyles,
        isClickable = true,
        isShowHeader,
        isEditable,
    } = props
    const [isDisplayInput] = useState(false)
    const isAuth = useAppSelector((state) => state.user.isAuth)
    const userProfile = useAppSelector((state) => state.user.me)
    const { width } = useGetWidthSize()

    const handleRedirectUrl = async (path = 'mypage') => {
        if (isAuth) {
            const url = await NftService.getRedirectUrl(
                userProfile.pialaAuthToken,
                path
            )
            window.location.href = url
        } else {
            window.location.href = `${process.env.REACT_APP_EC_API_URL}/mypage/login`
        }
    }

    return (
        <>
            {!isEditable && !isShowHeader && width < 768 ? (
                <div className="portal__header__hamburger">
                    <ClickableWrapper isClickable={isClickable}>
                        {width < 768 && (
                            <HeaderMenu
                                styleIconBar={{
                                    color: 'rgb(0, 0, 0)',
                                }}
                            />
                        )}
                    </ClickableWrapper>
                </div>
            ) : null}

            <div
                className="portal-groups__header__navigation"
                style={navigationStyles}
            >
                {isDisplayInput && <HeaderNavigation />}

                <div className="portal__header__menu__pc">
                    <ClickableWrapper isClickable={isClickable}>
                        <ul className="portal__header__menu__pc--wrapper">
                            <li>
                                <Link to="/portal">TOP</Link>
                            </li>
                            <li>
                                <a id="nft-mall-btn" href="/portal#nft-mall">
                                    {t('label.common.auction')}
                                </a>
                            </li>
                            <li>
                                <a
                                    id="home-special-btn"
                                    href="/portal#home-special"
                                >
                                    Special
                                </a>
                            </li>
                            <li>
                                <a href="/portal#creator-list">
                                    クリエイター一覧
                                </a>
                            </li>
                            <li>
                                <a
                                    href={`${process.env.REACT_APP_EC_API_URL}${process.env.REACT_APP_POINT_PRODUCTS}`}
                                >
                                    ポイント購入
                                </a>
                            </li>
                            <li>
                                <Link to="/portal/favorites">お気に入り</Link>
                            </li>
                            <li>
                                <Link to="/portal/guide/user">
                                    使い方ガイド
                                </Link>
                            </li>
                            <li>
                                <Link to="/portal/faq">FAQ</Link>
                            </li>
                            <li>
                                <div
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => handleRedirectUrl()}
                                    onClick={() => handleRedirectUrl()}
                                >
                                    マイページ
                                </div>
                            </li>
                            <li>
                                <a
                                    href={`${process.env.REACT_APP_EC_API_URL}/entry/artist`}
                                >
                                    クリエイター申請
                                </a>
                            </li>
                        </ul>
                    </ClickableWrapper>
                </div>
            </div>
        </>
    )
}

HeaderMainMenu.defaultProps = {
    navigationStyles: null,
    isClickable: true,
    isShowHeader: true,
    isEditable: true,
}

export default HeaderMainMenu
