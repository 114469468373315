import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

class NftService {
    public static baseURL =
        process.env.REACT_APP_NFT_URL ??
        'https://sherbet-dev-app-gcp.singuladev.net:1443'

    protected client: AxiosInstance

    private transition: string

    constructor() {
        this.client = axios.create({
            baseURL: NftService.baseURL,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_NFT_TOKEN}`,
            },
        })

        this.client.interceptors.response.use(
            NftService.handleFullFilled,
            NftService.handleRejected
        )

        this.transition = `${NftService.baseURL}/cyberstar/nft/transitions`
    }

    private static handleFullFilled(response: AxiosResponse) {
        return response
    }

    private static handleRejected(error: AxiosError) {
        return Promise.reject(error)
    }

    getRedirectUrl(
        authId: string | null = null,
        path: string,
        liveId: string | null = null,
        artistId: number | string | null = null
    ) {
        let data = {}
        if (authId) {
            data = {
                authId,
                path,
                artistId,
            }
        } else {
            data = {
                path,
                artistId,
            }
        }
        if (liveId) {
            data = {
                authId,
                path,
                artistId,
                liveId,
            }
        }

        return new Promise<string>((resolve, reject) => {
            this.client
                .post('/cyberstar/nft/transitions', data)
                .then((response) => {
                    resolve(response.data.url)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    getNftsArtist(artistId: number | string) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get(`/cyberstar/artists/${artistId}/auctions`)
                .then((data) => {
                    return resolve(data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    fetchRedirectUrl(authId: string) {
        return new Promise<string>((resolve, reject) => {
            fetch(this.transition, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_NFT_TOKEN}`,
                },
                redirect: 'manual',
                body: JSON.stringify({
                    authId,
                    path: 'auctions',
                }),
            })
                .then((response) => {
                    if (response.type === 'opaqueredirect') {
                        return resolve(response.url)
                    }

                    return resolve(this.transition)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    redirectUrlAuctions() {
        return new Promise<string>((resolve, reject) => {
            this.client
                .post('cyberstar/nft/transitions', { path: 'auctions' })
                .then((data) => {
                    return resolve(data?.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new NftService()
