import React from 'react'
import { List } from 'antd'
import { NewsType } from '../../../../type/NewsType'
import { formatDateTime } from '../../../../utils/filters'
import NewsIndexItem from './NewsIndexItem'
import { CreatorSettingColorType } from '../../../../hook/creator/useCreatorSettingColor'

type NewsIndexListProps = {
    news: NewsType[] | any
    currentPage: number
    transitionSource: string
    settingColor: CreatorSettingColorType
}

function NewsIndexList({
    news,
    currentPage,
    transitionSource,
    settingColor,
}: NewsIndexListProps): JSX.Element {
    return (
        <div className="portal-news__index__list__container">
            <List
                dataSource={news}
                renderItem={({
                    id,
                    title,
                    publish_at: publishAt,
                    url,
                }: NewsType) => {
                    const publishAtFormatted = publishAt
                        ? formatDateTime(publishAt)('YYYY/MM/DD')
                        : '------'
                    return (
                        <NewsIndexItem
                            id={id}
                            title={title}
                            publishAt={publishAtFormatted}
                            currentPage={currentPage}
                            transitionSource={transitionSource}
                            url={url}
                            settingColor={settingColor}
                        />
                    )
                }}
            />
        </div>
    )
}

export default NewsIndexList
