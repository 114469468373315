import { Action } from 'redux'
import {
    BlogComment,
    BlogCommentFlag,
    BlogCommentSendingStatus,
    BlogType,
} from '../../type/BlogType'

export type BlogStateType = {
    count: number
    isLoading: boolean
    list: Array<BlogType>
    comments: BlogComment[]
    blogEcDetail: BlogType | null
    blogOwnerId: number
    sendingStatus: {
        value: string
        status: BlogCommentSendingStatus | null
    } | null
}

export interface BlogActions<T = any> extends Action {
    payload?: T
}

export interface UpdateBlogRealTimeActions extends Action {
    payload: {
        flag: BlogCommentFlag
        comment: BlogComment
        ownerId: number
    }
}

export interface DeleteCommentActions extends Action {
    payload: {
        blogId: number
        commentId: number
        authId: string
    }
}

export const BlogActionsType = {
    count: 'BLOG_COUNT',
    fetchList: 'BLOG_FETCH_LIST',
    fetchBlogEcDetail: 'BLOG_FETCH_EC_DETAIL',
    fetchBlogComments: 'BLOG_FETCH_COMMENT',
    deleteBlogComment: 'BLOG_DELETE_COMMENT',
    setList: 'BLOG_SET_LIST',
    setLoading: 'BLOG_SET_LOADING',
    setCount: 'BLOG_SET_COUNT',
    setComments: 'BLOG_SET_COMMENTS',
    setBlogEcDetail: 'SET_BLOG_EC_DETAIL',
    setBlogOwnerId: 'SET_BLOG_OWNER_ID',
    setSendingStatus: 'SET_SENDING_STATUS',
    updateListCommentRealtime: 'UPDATE_LIST_COMMENT_REALTIME',
}
