import {
    GlobalLoadingActions,
    GlobalLoadingStateType,
    GlobalLoadingTypes,
} from '../constant/global-loading.const'

const initStates: GlobalLoadingStateType = {
    isGlobalLoading: false,
}

function GlobalLoadingReducer(
    state = initStates,
    actions: GlobalLoadingActions
): GlobalLoadingStateType {
    switch (actions.type) {
        case GlobalLoadingTypes.setGlobalLoading:
            return { ...state, isGlobalLoading: actions.payload }
        default:
            return state
    }
}

export default GlobalLoadingReducer
