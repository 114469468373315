import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Button, message } from 'antd'
import BaseModal from '../BaseModal'
import InputText from '../../form/inputs/InputText'
import InputFile from '../../form/inputs/InputFile'
import Form from '../../form/FormProvider'
import { useAppDispatch } from '../../../../store/hooks/AppHooks'
import { GlobalLoadingTypes } from '../../../../store/constant/global-loading.const'
import uploadFileService from '../../../../services/upload/uploadFile.service'
import { handleGetMsgError } from '../../../../utils/errorUtils'
import {
    ACCEPT_UPLOAD_CREATOR_SETTING,
    ACCEPT_UPLOAD_VIDEO_CREATOR_SETTING,
    MAX_IMAGE_UPLOAD_SIZE,
    MAX_VIDEO_UPLOAD_SIZE,
} from '../../../../utils/constant'
import { MediaType } from '../../../../type/MediaType'
import InputNumber from '../../form/inputs/InputNumber'

export type mediaTypes = 'video' | 'image'
export const MEDIA_TYPES = {
    VIDEO: 'video' as const,
    IMAGE: 'image' as const,
}

type Props = {
    type: mediaTypes
    isOpen: boolean
    onClose: () => void
    onSubmit: (url: string, width: number, type: mediaTypes) => void
}

type FormValueType = {
    file: File
    width: number
    url: string
}

const ModalUploadMedia = (props: Props): JSX.Element => {
    const { isOpen, type, onClose, onSubmit } = props
    const isUploadImage = type === MEDIA_TYPES.IMAGE
    const dispatch = useAppDispatch()
    const formMethods = useForm<FormValueType>()
    const { setValue } = formMethods
    const { t } = useTranslation()

    const handleSubmit = async (data: FormValueType) => {
        onSubmit(data.url, data.width, type)
    }

    const afterUpload = (res: MediaType, file: File) => {
        setValue('url', res.url)
        const reader = new FileReader()
        reader.onload = (e) => {
            if (type === MEDIA_TYPES.VIDEO) {
                const mediaElem = document.createElement(type)
                mediaElem.src = e.target?.result as string
                mediaElem.onloadedmetadata = () => {
                    setValue('width', mediaElem.videoWidth)
                }
            } else {
                const imgElem = new Image()
                imgElem.src = e.target?.result as string
                imgElem.onload = () => {
                    setValue('width', imgElem.width)
                }
            }
        }
        reader.readAsDataURL(file)
    }

    const beforeUpload = (file: File) => {
        if (file.type.includes('image') && file.size > MAX_IMAGE_UPLOAD_SIZE) {
            message.error(
                t('error.file.upload_max_size', {
                    size: '5MB',
                })
            )

            return false
        }

        if (file.type.includes('video') && file.size > MAX_VIDEO_UPLOAD_SIZE) {
            message.error(
                t('error.file.upload_max_size', {
                    size: '100MB',
                })
            )

            return false
        }
        dispatch({
            type: GlobalLoadingTypes.setGlobalLoading,
            payload: true,
        })
        uploadFileService
            .uploadTemp([file], isUploadImage)
            .then((res) => {
                afterUpload(res[0], file)
            })
            .catch((err) => {
                const errorMsg = handleGetMsgError(err.response)
                message.error(errorMsg)
            })
            .finally(() => {
                dispatch({
                    type: GlobalLoadingTypes.setGlobalLoading,
                    payload: false,
                })
            })
        return false
    }

    return (
        <BaseModal
            visible={isOpen}
            onCancel={onClose}
            title={t(
                isUploadImage
                    ? 'label.common.insert.image'
                    : 'label.common.insert.video'
            )}
            footer={null}
            className="modal-upload-media"
        >
            <Form useFormMethod={formMethods} onSubmit={handleSubmit}>
                <div className="form-control flex justify-start align-end width100">
                    <InputText
                        label={t('label.common.source')}
                        name="url"
                        className="width100"
                    />
                    <InputFile
                        beforeUpload={beforeUpload}
                        accept={
                            isUploadImage
                                ? ACCEPT_UPLOAD_CREATOR_SETTING
                                : ACCEPT_UPLOAD_VIDEO_CREATOR_SETTING
                        }
                        action=""
                        defaultFileList={[]}
                        customRequest={() => null}
                        name="file"
                    />
                </div>
                <div className="flex justify-start form-control items-center">
                    <div>
                        <InputNumber
                            label={t('label.common.width')}
                            name="width"
                            min={1}
                            className="mr-10"
                        />
                    </div>
                </div>
                <div className="mt-30 text-right">
                    <Button
                        htmlType="button"
                        className="ant-btn ant-btn-secondary mr-10"
                        onClick={onClose}
                    >
                        {t('label.common.cancel')}
                    </Button>
                    <Button
                        htmlType="submit"
                        className="ant-btn ant-btn-primary"
                    >
                        {t('label.common.save')}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    )
}

export default ModalUploadMedia
