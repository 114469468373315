import React from 'react'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import CreatorEditButton from '../common/Creator/CreatorEditButton'
import ClickableWrapper from '../common/ClickableWrapper'

type Props = {
    isEditable?: boolean
    isClickable?: boolean
    handleEdit?: () => void
    className?: string
    styleFooter?: React.CSSProperties
}
const Footer = (props: Props): JSX.Element => {
    const {
        isEditable,
        className,
        styleFooter,
        isClickable = true,
        handleEdit,
    } = props

    return (
        <footer className={`portal__footer ${className}`}>
            <div className="portal__footer__wrapper" style={{ ...styleFooter }}>
                {isEditable && handleEdit && (
                    <CreatorEditButton handleEdit={handleEdit} />
                )}
                <ClickableWrapper isClickable={isClickable}>
                    <div className="portal__footer__container portal__footer__container-col">
                        <div className="portal__footer__container portal__footer__container-row-small">
                            <div className="portal__footer__item-underlined">
                                <Link to="/portal/contact">
                                    <p>お問い合わせ</p>
                                </Link>
                            </div>
                            <div className="portal__footer__item-underlined">
                                <a
                                    href="https://www.piala.co.jp/profile/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <p>運営会社</p>
                                </a>
                            </div>
                            <div className="portal__footer__item-underlined">
                                <a
                                    href="https://www.piala.co.jp/privacy"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <p>プライバシーポリシー</p>
                                </a>
                            </div>
                            <div className="portal__footer__item-underlined">
                                <Link to="/portal/page/tradelaw">
                                    特定商取引法に基づく表記
                                </Link>
                            </div>
                            <div className="portal__footer__item-underlined">
                                <Link to="/portal/page/term-of-use">
                                    サイバースター・クリエイター利用規約
                                </Link>
                            </div>
                            <div className="portal__footer__item-underlined">
                                <Link to="/portal/page/membership-term-of-use">
                                    サイバースター・ユーザー利用規約
                                </Link>
                            </div>
                            <div className="portal__footer__item-underlined">
                                <Link to="/portal/page/nft-terms-of-services">
                                    {t('label.common.footer.auction_terms')}
                                </Link>
                            </div>
                        </div>

                        <div className="portal__footer__container portal__footer__container-row-small">
                            <div className="portal__footer__item-copy_right">
                                <p>COPYRIGHT &copy; CYBERSTAR/PIALA</p>
                            </div>
                        </div>
                    </div>
                </ClickableWrapper>
            </div>
        </footer>
    )
}
Footer.defaultProps = {
    isEditable: false,
    className: '',
    handleEdit: undefined,
    styleFooter: undefined,
    isClickable: true,
}
export default Footer
