import moment from 'moment'
import _ from 'lodash'

export const formatDateTime =
    (value: string) =>
    (format?: string): string => {
        if (format) {
            return moment(value).format(format)
        }
        return moment(value).format('HH:mm DD-MM-YYYY')
    }

export const readableNumber = (
    labelValue: number | string | undefined
): string | number => {
    const formatNumber = Math.abs(Number(labelValue))
    // Billions
    if (formatNumber >= 1.0e9) {
        return `${(formatNumber / 1.0e9).toFixed(2).replace(/\.?0+$/, '')}B`
    }
    // Millions
    if (formatNumber >= 1.0e6) {
        return `${(formatNumber / 1.0e6).toFixed(2).replace(/\.?0+$/, '')}M`
    }
    // Thousands
    if (formatNumber >= 1.0e3) {
        return `${(formatNumber / 1.0e3).toFixed(2).replace(/\.?0+$/, '')}K`
    }

    return formatNumber
}

/**
 * Format object keys to camelCase
 * @param obj
 */
export const formatKeys = (obj: any) => {
    return _.mapKeys(obj, (v, k) => _.camelCase(k))
}

export const formatMediaUrl = (url: string) => {
    if (url.indexOf('https://') >= 0 || url.indexOf('http://') >= 0) {
        return url
    }

    const cmsHost = process.env.REACT_APP_API_URL
    return `${cmsHost}${url}`
}

export const formatLinkUrl = (url: string): formatLinkUrlReturnType => {
    let formattedUrl
    if (url === '' || url === undefined) {
        formattedUrl = '/'
    } else {
        formattedUrl = url
    }

    const isAbsolutePath =
        url?.indexOf('https://') >= 0 || url?.indexOf('http://') >= 0

    return { destination: formattedUrl, isAbsolutePath }
}

type formatLinkUrlReturnType = { destination: string; isAbsolutePath: boolean }

export function formatNumWithCommas(num: number | string): string {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Cut text when placing text in a small space
 *
 * @param text
 * @param count
 * @returns
 */
export function formatStringAdjustByLength(
    text: string,
    count: number
): string {
    return text.length > count ? `${text.substring(0, count)}…` : text
}

/**
 * get difference between two date time
 *
 * @param earlierDate
 * @param laterDate
 * @param unitInJa
 * @returns
 */
export function getDiffBetweenTwoDateTime(
    earlierDate: string,
    laterDate: string,
    unitInJa: boolean
): string {
    const subtractingDate = moment(earlierDate)
    const subtractedDate = moment(laterDate)

    const diffMinutes = subtractedDate.diff(subtractingDate, 'minutes')

    if (diffMinutes < 60) {
        return unitInJa ? `${diffMinutes} 分` : `${diffMinutes} minutes`
    }

    if (diffMinutes % 60 === 0) {
        const diffHoursExact = subtractedDate.diff(subtractingDate, 'hours')
        return unitInJa ? `${diffHoursExact} 時間` : `${diffHoursExact} hours`
    }
    const diffHours = subtractedDate.diff(subtractingDate, 'hours', true)

    return unitInJa
        ? `${diffHours.toFixed(1)} 時間`
        : `${diffHours.toFixed(1)} hours`
}

export function hexToRgb(hex: string) {
    const fullFormat = hex.replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m: string, r: string, g: string, b: string) =>
            `#${r}${r}${g}${g}${b}${b}`
    )
    const rgb = fullFormat
        .substring(1)
        ?.match(/.{2}/g)
        ?.map((x: string) => parseInt(x, 16))
        .toString()
    return rgb ?? null
}

export function typedKeys<T>(obj: T) {
    return Object.keys(obj) as (keyof T)[]
}
