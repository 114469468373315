import * as _ from 'lodash'
import {
    ArtistGroupActionType,
    ArtistGroupActions,
    ArtistGroupStateType,
} from '../constant/group.const'
import { GroupCms } from '../../type/GroupType'

const initialState: ArtistGroupStateType = {
    isLoading: false,
    isFetched: false,
    currentGroupCmsId: 0,
    currentGroupDataCms: {} as GroupCms,
    errors: [],
    news: [],
    artistsList: [],
    groupsEc: [],
    topSlides: [],
    middleSlides: [],
    ecMalls: [],
    liveTickets: [],
    favorites: [],
    isFavorited: false,
    favoriteLoading: false,
    stringSearched: '',
    loadingArtistList: false,
    artistsInfo: [],
    groupProductsUrl: '',
    nftProduct: '',
}

export default function groupReducers(
    state = initialState,
    actions: ArtistGroupActions
): ArtistGroupStateType {
    switch (actions.type) {
        case ArtistGroupActionType.setLoading:
            return { ...state, isLoading: actions.payload }
        case ArtistGroupActionType.setFetched:
            return { ...state, isFetched: actions.payload }
        case ArtistGroupActionType.setCurrentGroupCmsId:
            return { ...state, currentGroupCmsId: actions.payload }
        case ArtistGroupActionType.setCurrentGroupData:
            return { ...state, currentGroupDataCms: actions.payload }
        case ArtistGroupActionType.setErrors:
            return { ...state, errors: [...state.errors, actions.payload] }
        case ArtistGroupActionType.resetErrors:
            return { ...state, errors: [] }
        case ArtistGroupActionType.setNews:
            return { ...state, news: actions.payload }
        case ArtistGroupActionType.setArtistsList:
            return { ...state, artistsList: actions.payload }
        case ArtistGroupActionType.setGroupsFromEc:
            return { ...state, groupsEc: actions.payload }
        case ArtistGroupActionType.setGroupTopSlides: {
            const sorted = _.sortBy(actions.payload, 'sortOrder')
            return { ...state, topSlides: sorted }
        }
        case ArtistGroupActionType.setGroupTopMiddleSlides: {
            const sorted = _.sortBy(actions.payload, 'sortOrder')
            return { ...state, middleSlides: sorted }
        }
        case ArtistGroupActionType.setGroupEcMalls:
            return { ...state, ecMalls: actions.payload }
        case ArtistGroupActionType.setGroupLiveTickets:
            return { ...state, liveTickets: actions.payload }
        case ArtistGroupActionType.setStringSearched:
            return { ...state, stringSearched: actions.payload }
        case ArtistGroupActionType.setLoadingArtistList:
            return { ...state, loadingArtistList: actions.payload }
        case ArtistGroupActionType.setArtistsInfo:
            return { ...state, artistsInfo: actions.payload }
        case ArtistGroupActionType.setFavorites:
            return { ...state, favorites: actions.payload }
        case ArtistGroupActionType.setFavorited:
            return { ...state, isFavorited: actions.payload }
        case ArtistGroupActionType.setFavoriteLoading:
            return { ...state, favoriteLoading: actions.payload }
        case ArtistGroupActionType.setGroupProductsUrl:
            return { ...state, groupProductsUrl: actions.payload }
        case ArtistGroupActionType.setNftProductGroup:
            return { ...state, nftProduct: actions.payload }
        default:
            return state
    }
}
