import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { CreatorType } from '../../type/CreatorType'
import artistCmsService from '../../services/artist/artistCms.service'
import { LIMIT_LIST_NEWS } from '../../utils/constant'
import groupCmsService from '../../services/groups/groupCms.service'
import { ArtistNewsType } from '../../type/ArtistType'
import { GroupNewsType } from '../../type/GroupType'

type CreatorNewsHookType = {
    artistNews: ArtistNewsType[]
    groupNews: GroupNewsType[]
    isLoading: boolean
    total: number
    page: number
    setPage: (page: number) => void
}
const useCreatorNews = (
    creatorCms: CreatorType | null,
    isArtist: boolean,
    limit = LIMIT_LIST_NEWS
): CreatorNewsHookType => {
    const { t } = useTranslation()
    const [artistNews, setArtistNews] = useState<ArtistNewsType[]>([])
    const [groupNews, setGroupNews] = useState<GroupNewsType[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)

    const fetchData = async () => {
        if (!creatorCms) return

        setIsLoading(true)
        if (isArtist) {
            await artistCmsService
                .getListNews(creatorCms?.id, limit, (page - 1) * limit)
                .then((data) => {
                    setArtistNews(data)
                })
                .catch(() => {
                    message.error(t('error.server_error'))
                })

            await artistCmsService
                .getCountNews(creatorCms.id)
                .then((data) => {
                    setTotal(data)
                })
                .catch(() => {
                    message.error(t('error.server_error'))
                })
        } else {
            await groupCmsService
                .getListGroupNews(creatorCms.id, limit, (page - 1) * limit)
                .then((data) => {
                    setGroupNews(data)
                })
                .catch(() => {
                    message.error(t('error.server_error'))
                })

            await groupCmsService
                .getCountNews(creatorCms.id)
                .then((data) => {
                    setTotal(data)
                })
                .catch(() => {
                    message.error(t('error.server_error'))
                })
        }
        setIsLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [creatorCms, isArtist, limit, page])

    return {
        artistNews,
        groupNews,
        isLoading,
        total,
        page,
        setPage,
    }
}

export default useCreatorNews
