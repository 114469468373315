import { FaqStateType, FaqActions, FaqActionTypes } from '../constant/faq.const'

const initialState: FaqStateType = {
    isLoading: false,
    list: [],
    categories: [],
    categoryActive: '',
    searchValue: '',
    ids: [],
    listSearched: [],
}

function faqReducers(state = initialState, actions: FaqActions): FaqStateType {
    switch (actions.type) {
        case FaqActionTypes.setLoading:
            return { ...state, isLoading: actions.payload }
        case FaqActionTypes.setCategories:
            return { ...state, categories: actions.payload }
        case FaqActionTypes.setList:
            return { ...state, list: actions.payload }
        case FaqActionTypes.setSearchValue:
            return { ...state, searchValue: actions.payload }
        case FaqActionTypes.setCategoryActive:
            return { ...state, categoryActive: actions.payload }
        case FaqActionTypes.setListIds:
            return { ...state, ids: actions.payload }
        case FaqActionTypes.setListSearched:
            return { ...state, listSearched: actions.payload }
        case FaqActionTypes.changeStateAfterLoading:
            return {
                ...state,
                listSearched: state.list.length > 0 ? state.list : [],
                ids:
                    state.list.length > 0
                        ? state.list.map(({ id }) => {
                              return id
                          })
                        : [],
            }
        default:
            return state
    }
}

export default faqReducers
