import _ from 'lodash'
import { URLSearchParams } from 'url'
import { AxiosError } from 'axios'
import { message } from 'antd'
import EcService from '../ec.service'
import {
    ArtistCategoryType,
    ArtistType,
    ArtistMenuType,
    ArtistGroupType,
    ArtistCustomerInfoType,
} from '../../type/ArtistType'
import { QueryParams } from '../../type/QueryParams'
import { formatKeys } from '../../utils/filters'
import { FavoriteType } from '../../type/FavoriteType'
import { ArtistFanClubRankListParams } from '../../type/FanClubType'

export interface ArtistQueryParams extends QueryParams {
    id?: number
    name?: string
}

class ArtistService extends EcService {
    /**
     * Get the raw list of artists from Ec Cube
     * @param params
     */
    public getList(params: ArtistQueryParams = {}) {
        return new Promise<Array<ArtistType>>((resolve, reject) => {
            this.client
                .get('GetArtistList', {
                    params,
                })
                .then((response) => {
                    const artists = response.data.data.map((artist: any) => {
                        return {
                            id: artist.artist_id,
                            name: artist.artist_name,
                            memberGroupCnt: artist.member_group_cnt,
                            favoritesCnt: artist.favorites_cnt,
                            isLive: artist.is_live,
                            liveId: artist.live_id,
                            isGroup: artist.is_group,
                            groupId: artist.group_id,
                            isFcOnly: artist.is_fc_only,
                            artistNftId: artist.artist_nft_id,
                        }
                    })
                    resolve(artists)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get the artist's categories
     * @param params
     */
    public getCategories(params: ArtistQueryParams = {}) {
        return new Promise<Array<ArtistCategoryType>>((resolve, reject) => {
            this.client
                .get('GetArtistCategoryList', {
                    params,
                })
                .then((response) => {
                    resolve(response.data.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get configured menus
     * @param artistId
     */
    public getMenus(artistId: number | string) {
        return new Promise<Array<ArtistMenuType>>((resolve, reject) => {
            this.client
                .get('GetArtistMenu', {
                    params: {
                        artist_id: artistId,
                    },
                })
                .then((response) => {
                    // Formatting keys to camelCase
                    const data = _.map(response.data.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(data as ArtistMenuType[])
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get Favorites list from Ec cube
     * @param customerId
     * @param artistId
     * @param categoryId
     */
    public getFavorites(
        customerId: number | null = null,
        artistId: number | null = null,
        categoryId: number | null = null
    ) {
        return new Promise<FavoriteType[]>((resolve, reject) => {
            this.client
                .get('FavoriteArtist', {
                    params: {
                        artist_id: artistId,
                        customer_id: customerId,
                        category_id: categoryId ?? null,
                    },
                })
                .then((response) => {
                    const data = _.map(response.data.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(data as FavoriteType[])
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    /**
     * Post a new favorite artist
     * @param params
     */
    public addFavoriteArtist(params: URLSearchParams) {
        return new Promise<string>((resolve, reject) => {
            this.client
                .post('FavoriteArtist', params)
                .then((response) => {
                    resolve('お気に入り登録をしました')
                    message.info('お気に入り登録をしました')
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    /**
     * Delete a favorite artist of a login user
     * @param params
     */
    public deleteFavoriteArtist(params: URLSearchParams) {
        return new Promise<string>((resolve, reject) => {
            this.client
                .delete('FavoriteArtist', {
                    data: params,
                })
                .then((response) => {
                    resolve('お気に入りを削除しました。')
                    message.info('お気に入りを削除しました。')
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getMemberGroups(id: string) {
        return new Promise<Array<ArtistGroupType>>((resolve, reject) => {
            this.client
                .get('GetCustomerGroup', {
                    params: { id },
                })
                .then((response) => {
                    const data = _.map(response.data.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(data as Array<ArtistGroupType>)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getCustomerInfoById(id: number) {
        return new Promise<ArtistCustomerInfoType>((resolve, reject) => {
            this.client
                .get('getCustomerInfo', {
                    params: { id },
                })
                .then((response) => {
                    resolve(response.data as ArtistCustomerInfoType)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getArtistFanClubList(id: string | number) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get('GetArtistFanClubList', {
                    params: { artist_id_ec: id },
                })
                .then((response) => {
                    resolve(response.data.data as any)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getFanClubList() {
        return new Promise<Array<any>>((resolve, reject) => {
            this.client
                .get('GetArtistFanClubList')
                .then((response) => {
                    const data = _.map(response.data.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(data as Array<any>)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getFanClubMemberList(customerId: number) {
        return new Promise<Array<any>>((resolve, reject) => {
            this.client
                .get(`GetFanClubMemberList?customer_id=${customerId}`)
                .then((response) => {
                    const data = _.map(response.data.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(data as Array<any>)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getArtistFanClubRankList(
        params: ArtistFanClubRankListParams | null = null
    ) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get('GetArtistFanClubRankList', {
                    params: {
                        artist_id: params?.artistId,
                        artist_group_id: params?.artistGroupId,
                        artist_id_ec: params?.artistIdEc,
                    },
                })
                .then((response) => {
                    resolve(response.data.data as any)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getDetailCategory(id: string | number) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get('GetDetailCategory', {
                    params: { artist_id: id },
                })
                .then((response) => {
                    resolve(response.data.data as any)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getButtonRedirectUrl(authId: string | null = null, path: string) {
        let data = {}
        if (authId) {
            data = {
                authId,
                path,
            }
        } else {
            data = {
                path,
            }
        }

        return new Promise<string>((resolve, reject) => {
            this.client
                .post('/cyberstar/nft/transitions', data)
                .then((response) => {
                    resolve(response.data.url)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }
}

export default new ArtistService()
