import React, { useEffect } from 'react'
import * as _ from 'lodash'
import { RouteChildrenProps, useLocation } from 'react-router-dom'
import PortalRoutes from '../routes/PortalRoutes'
import withMasterLayout from '../components/hoc/WithMasterLayout'
import GroupHeader from '../components/portal/groups/GroupHeader'
import { useAppSelector } from '../store/hooks/AppHooks'
import settingStyle, {
    isCreatorPage as checkIsCreatorPage,
} from '../utils/utils'
import { FrontTheme } from '../type/FrontTheme'
import CreatorHeader from '../components/common/Creator/CreatorHeader'
import useCreatorElementStatuses, {
    CREATOR_STATUS_KEYS,
} from '../hook/creator/useCreatorElementStatuses'
import useCreatorSettingColor from '../hook/creator/useCreatorSettingColor'

export const GroupContext = React.createContext({})

function Layout({ match }: RouteChildrenProps): JSX.Element {
    const settings = useAppSelector(
        (state) => state.artistGroup.currentGroupDataCms
    )
    const root = document.documentElement.style
    const location = useLocation()
    const isCreatorPage = checkIsCreatorPage(location)
    const groupInfo = useAppSelector(
        (state) => state.artistGroup.currentGroupDataCms
    )
    const { getStatusElement } = useCreatorElementStatuses(groupInfo)
    const { settingColor } = useCreatorSettingColor(groupInfo)

    useEffect(() => {
        if (!_.isEmpty(settings)) {
            settingStyle(root, {
                buttonColor: settings.buttonColor,
                backgroundColor: settings.backgroundColor,
                sideAreaColor: settings.sideAreaColor,
            } as FrontTheme)
        }
        document
            .querySelector('.portal__wrapper')
            ?.classList.add('group__background-color')
        document
            .querySelector('.portal .portal__container')
            ?.classList.add('group__container-color')
    }, [root, settings])

    return (
        <GroupContext.Provider value={{}}>
            <section className="portal portal__wrapper">
                <div className="portal__container">
                    {isCreatorPage && groupInfo ? (
                        <CreatorHeader
                            position="fixed"
                            settingColor={settingColor}
                            isShow={getStatusElement(
                                CREATOR_STATUS_KEYS.IS_SHOW_HEADER_KEY
                            )}
                        />
                    ) : (
                        <GroupHeader />
                    )}
                    <PortalRoutes
                        prefix={match ? match.path : ''}
                        isCreatorPage={isCreatorPage}
                        isArtist={false}
                    />
                </div>
            </section>
        </GroupContext.Provider>
    )
}
const ArtistGroupLayout = withMasterLayout<RouteChildrenProps>(Layout)
export default ArtistGroupLayout
