import { call, put, takeLatest, select } from 'redux-saga/effects'
import { message } from 'antd'
import { t } from 'i18next'
import { UserActions, UserActionTypes } from '../constant/user.const'
import { UserType } from '../../type/UserType'
import UserService from '../../services/user.service'
import UserEcService from '../../services/portal/user.serevice'
import { rootState } from '../index'
import PointService from '../../services/point.service'
import { PointType } from '../../type/PointType'
import { AuthService } from '../../services/auth.service'
import authEcService from '../../services/auth/authEc.service'
import { AuthEcInfoType } from '../../type/AuthType'

export function* getProfile() {
    // Set loading
    yield put({
        type: UserActionTypes.setLoading,
        payload: true,
    } as UserActions)
    // Get profile
    try {
        const me: UserType = yield call(
            UserService.getProfile.bind(UserService)
        )
        if (!me?.pialaAuthToken) {
            AuthService.removeToken()
            yield put({ type: UserActionTypes.setAuth, payload: false })
            window.location.href = `${process.env.REACT_APP_EC_API_URL}/prelogout`
        } else {
            yield put({
                type: UserActionTypes.setProfile,
                payload: me,
            } as UserActions)
        }
    } catch (error) {
        // TODO: handle the error
        console.error(error)
    }

    // Set loading to false
    yield put({
        type: UserActionTypes.setLoading,
        payload: false,
    } as UserActions)
}

/**
 * Logout for CMS & aurth server
 */
export function* logoutForCms() {
    try {
        // get customer id from Redux store
        const authId: string = yield select(
            (state: rootState) => state.user.me.pialaAuthToken
        )
        // constant to pass the parm of request body
        const redirectURL = window.location.origin

        // construct request body and add its key & value
        const params = new URLSearchParams()
        params.append('auth_id', authId)
        params.append('url', redirectURL)

        // call API to logout & redirect to the top page and store the response
        const result: string = yield call(
            UserEcService.logoutForCms.bind(UserEcService),
            params
        )

        // if API responsed successfully, execute logout process on the front-end side
        if (result === 'success') {
            yield put({
                type: UserActionTypes.setResultAfterTryLogout,
                payload: {
                    feedback: result,
                    message: '',
                },
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: UserActionTypes.setResultAfterTryLogout,
            payload: {
                feedback: 'failure',
                message:
                    'システムエラーが発生しました。 大変お手数ですが、サイト管理者までご連絡ください。',
            },
        })
    }
}

export function* getPoint() {
    // Get point
    try {
        const id: number = yield select(
            (state: rootState) => state.user.me.ecCubeId
        )
        const idToStrintg = id?.toString()
        const points: PointType = yield call(
            PointService.get.bind(PointService),
            idToStrintg
        )
        yield put({
            type: UserActionTypes.setPoints,
            payload: points,
        } as UserActions)
    } catch (error) {
        console.error(error)
    }
}

export function* getAuthEcInfo(actions: any) {
    yield put({
        type: UserActionTypes.setLoading,
        payload: true,
    } as UserActions)

    try {
        const authInfo: AuthEcInfoType = yield call(
            authEcService.authInfo.bind(authEcService),
            actions.payload
        )

        yield put({
            type: UserActionTypes.setAuthEcInfo,
            payload: authInfo,
        } as UserActions)
    } catch (error) {
        message.error(t('error.server_error'))
    } finally {
        yield put({
            type: UserActionTypes.setLoading,
            payload: false,
        } as UserActions)
    }
}

export function* userSaga() {
    yield takeLatest(UserActionTypes.getProfile, getProfile)
    yield takeLatest(UserActionTypes.getAuthEcInfo, getAuthEcInfo)
    yield takeLatest(UserActionTypes.requestLogout, logoutForCms)
    yield takeLatest(UserActionTypes.fetchPoints, getPoint)
}
