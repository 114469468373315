import React from 'react'

type Props = {
    children: React.ReactNode
    isClickable?: boolean
}
const ClickableWrapper = (props: Props): JSX.Element => {
    const { children, isClickable = true } = props

    return (
        <div
            style={{
                pointerEvents: isClickable ? 'all' : 'none',
            }}
        >
            {children}
        </div>
    )
}

ClickableWrapper.defaultProps = {
    isClickable: true,
}

export default ClickableWrapper
