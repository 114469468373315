/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect } from 'react'
import { Spin, Empty, Pagination } from 'antd'
import _ from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../store/hooks/AppHooks'
import Loading from '../../Loading'
import NewsIndexList from './NewsIndexList'
import { ArtistGroupActionType } from '../../../../store/constant/group.const'
import useCreatorDetail from '../../../../hook/creator/useCreatorDetail'
import useCreatorNews from '../../../../hook/creator/useCreatorNews'
import { DEFAULT_NEWS_LIMIT } from '../../../../utils/constant'
import useCreatorSettingColor, {
    COLOR_KEYS,
} from '../../../../hook/creator/useCreatorSettingColor'
import { getBackgroundCss } from '../../../../utils/creatorUtil'
import CreatorComponent from '../../../common/Creator/CreatorComponent'

type Props = {
    isArtist: boolean
}
const NewsList = ({ isArtist }: Props): JSX.Element => {
    const { slug } = useParams<{ slug: string }>()
    const dispatch = useAppDispatch()
    const history = useHistory<{ currentPage?: number }>()
    const pathName = history.location.pathname
    const { creatorCms } = useCreatorDetail(slug, isArtist)
    const { settingColor } = useCreatorSettingColor(creatorCms)
    const { artistNews, groupNews, total, page, isLoading, setPage } =
        useCreatorNews(creatorCms, isArtist, DEFAULT_NEWS_LIMIT)
    const listNews = isArtist ? artistNews : groupNews
    const groupCmsIdCurrent = useAppSelector(
        (state) => state.artistGroup.currentGroupCmsId
    )
    const isGroupFetched = useAppSelector(
        (state) => state.artistGroup.isFetched
    )

    const handleBackToTop = () => {
        if (isArtist) {
            history.push(`/portal/artist/${slug}`)
        } else {
            history.push(`/groups/${groupCmsIdCurrent}`)
        }
    }

    useEffect(() => {
        if (isGroupFetched) {
            dispatch({
                type: ArtistGroupActionType.fetchNews,
            })
        }
    }, [dispatch, isGroupFetched])

    const newsArray = listNews?.map((ele: any) => {
        const url = isArtist
            ? `/portal/artist/${slug}/news/${ele?.id}`
            : `/groups/${groupCmsIdCurrent}/news/${ele?.id}`

        return {
            id: ele?.id,
            title: ele?.title,
            content: ele?.content,
            publish_at: ele?.publishAt,
            url,
        }
    })

    return (
        <CreatorComponent>
            <section className="portal-news__index portal__page group-news-list">
                <div
                    className="portal__page__container"
                    style={{
                        color:
                            settingColor?.[COLOR_KEYS.COLOR_TEXT_GENERAL_KEY]
                                ?.hex ?? '',
                        background: creatorCms?.backgroundType
                            ? getBackgroundCss(
                                  creatorCms.backgroundType,
                                  settingColor?.[
                                      COLOR_KEYS.COLOR_BACKGROUND_PAGE_KEY
                                  ]?.hex,
                                  creatorCms?.backgroundImage?.url
                              )
                            : 'none',
                    }}
                >
                    <div className="portal__page__header">
                        <h3
                            style={{
                                color:
                                    settingColor?.[
                                        COLOR_KEYS.COLOR_TEXT_GENERAL_KEY
                                    ]?.hex ?? '',
                            }}
                        >
                            NEWS
                        </h3>
                    </div>
                    <div className="portal-news__index__list">
                        <Spin indicator={<Loading />} spinning={isLoading}>
                            {!_.isEmpty(listNews) ? (
                                <NewsIndexList
                                    news={newsArray}
                                    currentPage={1}
                                    transitionSource={pathName}
                                    settingColor={settingColor}
                                />
                            ) : (
                                <Empty />
                            )}
                        </Spin>
                    </div>
                    <div>
                        {listNews && total > DEFAULT_NEWS_LIMIT ? (
                            <div
                                className={`portal-news__index__paginator__container ${
                                    page > 1 ? 'paginate-style-in-page-2 ' : ''
                                } `}
                            >
                                <Pagination
                                    total={total}
                                    onChange={setPage}
                                    pageSize={DEFAULT_NEWS_LIMIT}
                                    showLessItems
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="portal-news__index__link">
                        <button type="button" onClick={handleBackToTop}>
                            &lt; トップへ戻る {/** return to top page */}
                        </button>
                    </div>
                </div>
            </section>
        </CreatorComponent>
    )
}

export default NewsList
