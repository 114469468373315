import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationJP from './assets/locales/jp.json'

const resources = {
    jp: {
        translation: translationJP,
    },
}

i18n.use(initReactI18next).init({
    resources,
    lng: 'jp',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
})

export default i18n
