import React from 'react'
import {
    DEFAULT_FOOTER_BG_COLOR,
    DEFAULT_FOOTER_COLOR,
    MODAL_EDIT_SETTING_CREATOR_FOOTER,
} from '../../../../utils/constant'
import { useModalState } from '../../../../hook/useModalState'
import Footer from '../../../portal/Footer'
import BottomNav from '../../../portal/BottomNav'
import ModalSettingFooter from '../../modals/listModalSettingCreator/ModalSettingFooter'
import {
    COLOR_KEYS,
    CreatorSettingColorType,
} from '../../../../hook/creator/useCreatorSettingColor'

type Props = {
    isEditable?: boolean
    settingColor: CreatorSettingColorType
    isClickable?: boolean
    className?: string
    updateSettingColor?: (data: Partial<CreatorSettingColorType>) => void
}

export type BackgroundType = Pick<
    CreatorSettingColorType,
    typeof COLOR_KEYS.COLOR_BACKGROUND_FOOTER_KEY
>

const CreatorFooter = (props: Props): JSX.Element | null => {
    const {
        isEditable,
        settingColor,
        className,
        isClickable = true,
        updateSettingColor,
    } = props
    const { modalState, open, close } = useModalState({
        [MODAL_EDIT_SETTING_CREATOR_FOOTER]: false,
    })

    const handleOkModalFooter = (settingsColor: BackgroundType) => {
        if (updateSettingColor) {
            updateSettingColor(settingsColor)
        }
        close()
    }

    return (
        <>
            <Footer
                isEditable={isEditable}
                isClickable={isClickable}
                handleEdit={() => open(MODAL_EDIT_SETTING_CREATOR_FOOTER)}
                styleFooter={{
                    backgroundColor:
                        settingColor[COLOR_KEYS.COLOR_BACKGROUND_FOOTER_KEY]
                            .hex ?? DEFAULT_FOOTER_BG_COLOR,
                    color:
                        settingColor[COLOR_KEYS.COLOR_TEXT_FOOTER_KEY].hex ??
                        DEFAULT_FOOTER_COLOR,
                }}
                className={`pt-0 ${className}`}
            />
            <BottomNav
                isEditable={isEditable}
                className={`portal__bottom-navigator${
                    isEditable ? '-edit' : ''
                }`}
                colorBackground={
                    settingColor[COLOR_KEYS.COLOR_BACKGROUND_FOOTER_KEY].hex ??
                    DEFAULT_FOOTER_BG_COLOR
                }
                colorText={
                    settingColor[COLOR_KEYS.COLOR_TEXT_FOOTER_KEY].hex ??
                    DEFAULT_FOOTER_COLOR
                }
            />
            {modalState?.[MODAL_EDIT_SETTING_CREATOR_FOOTER] && isEditable && (
                <ModalSettingFooter
                    isModalOpen={
                        modalState?.[MODAL_EDIT_SETTING_CREATOR_FOOTER]
                    }
                    colorBackground={settingColor}
                    colorText={settingColor}
                    onOk={handleOkModalFooter}
                    onCancel={close}
                />
            )}
        </>
    )
}

CreatorFooter.defaultProps = {
    isEditable: false,
    updateSettingColor: undefined,
    isClickable: true,
    className: undefined,
}

export default CreatorFooter
