import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RadioChangeEvent } from 'antd'
import { ColorResult, SketchPicker } from 'react-color'
import BaseModalSettingCreator from './BaseModalSettingCreator'
import CreatorRadioGroup from '../../Creator/CreatorRadioGroup'
import {
    COLOR_KEYS,
    CreatorSettingColorType,
} from '../../../../hook/creator/useCreatorSettingColor'
import { converRgbaToHex } from '../../../../utils/utils'

export type HeaderColorSetting = Pick<
    CreatorSettingColorType,
    | typeof COLOR_KEYS.COLOR_TEXT_HEADER_KEY
    | typeof COLOR_KEYS.COLOR_BACKGROUND_HEADER_KEY
>

type Props = {
    isShowHeader: boolean
    isModalOpen: boolean
    settingColor: CreatorSettingColorType
    onOk: (settingsColor: HeaderColorSetting, isShowHeader: boolean) => void
    onCancel: () => void
}

const ModalSettingHeader = (props: Props): JSX.Element => {
    const { t } = useTranslation()
    const { onOk, onCancel, settingColor, isShowHeader, isModalOpen } = props
    const [showHeaderValue, setShowheaderValue] =
        useState<boolean>(isShowHeader)
    const [headerColorSettingState, setHeaderColorSettingState] =
        useState<HeaderColorSetting>({
            [COLOR_KEYS.COLOR_TEXT_HEADER_KEY]:
                settingColor?.[COLOR_KEYS.COLOR_TEXT_HEADER_KEY],
            [COLOR_KEYS.COLOR_BACKGROUND_HEADER_KEY]:
                settingColor?.[COLOR_KEYS.COLOR_BACKGROUND_HEADER_KEY],
        })

    const onChangeRadio = (e: RadioChangeEvent) => {
        setShowheaderValue(e.target.value)
    }

    const onChangeColor = (
        key:
            | typeof COLOR_KEYS.COLOR_TEXT_HEADER_KEY
            | typeof COLOR_KEYS.COLOR_BACKGROUND_HEADER_KEY,
        color: ColorResult
    ) => {
        setHeaderColorSettingState({
            ...headerColorSettingState,
            [key]: {
                hex: converRgbaToHex(color.rgb),
                rgb: color.rgb,
            },
        })
    }

    const handleOk = () => {
        onOk(headerColorSettingState, showHeaderValue)
    }

    return (
        <BaseModalSettingCreator
            onOk={handleOk}
            onCancel={onCancel}
            visible={isModalOpen}
            title={t('label.page.setting.header')}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <div className="modal-setting-header">
                <div className="modal-setting-header__setting-visibility">
                    <div>{t('label.page.setting.header.is_show_header')}</div>
                    <CreatorRadioGroup
                        name="header"
                        defaultValue={showHeaderValue}
                        onChange={onChangeRadio}
                        className="modal-setting-header__setting-visibility--radio-group"
                    />
                </div>
                <div className="modal-setting-header__setting-color">
                    <div className="modal-setting-header__setting-color--item">
                        <div className="form-control__label">
                            {t('label.common.text_color')}
                        </div>
                        <SketchPicker
                            color={
                                headerColorSettingState[
                                    COLOR_KEYS.COLOR_TEXT_HEADER_KEY
                                ]?.rgb
                            }
                            onChange={(color) =>
                                onChangeColor(
                                    COLOR_KEYS.COLOR_TEXT_HEADER_KEY,
                                    color
                                )
                            }
                            width="250px"
                        />
                    </div>
                    <div className="modal-setting-header__setting-color--item">
                        <div className="modal-setting-header__setting-color--label">
                            {t('label.common.background_color')}
                        </div>
                        <SketchPicker
                            color={
                                headerColorSettingState[
                                    COLOR_KEYS.COLOR_BACKGROUND_HEADER_KEY
                                ]?.rgb
                            }
                            onChange={(color) =>
                                onChangeColor(
                                    COLOR_KEYS.COLOR_BACKGROUND_HEADER_KEY,
                                    color
                                )
                            }
                            width="250px"
                        />
                    </div>
                </div>
            </div>
        </BaseModalSettingCreator>
    )
}

export default ModalSettingHeader
