import React from 'react'
import { Link } from 'react-router-dom'
import imageCrown from '../../assets/images/portal/image_ranking/ico_ranking.png'

function HeaderRankingLink(): JSX.Element {
    return (
        <div className="portal__header__ranking__wrapper">
            <Link to="#">
                <div className="portal__header__ranking__image-container">
                    {/* <img */}
                    {/* src={imageCrown} */}
                    {/* alt="RANKING" */}
                    {/* className="portal__header__ranking__image-image" */}
                    {/* /> */}
                </div>
            </Link>
        </div>
    )
}

export default HeaderRankingLink
