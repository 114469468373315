import {
    UserStateType,
    UserActions,
    UserActionTypes,
} from '../constant/user.const'
import { UserType } from '../../type/UserType'

const initialState: UserStateType = {
    isLoading: false,
    me: {} as UserType,
    isAuth: false,
    resultAfterTryLogout: { feedback: '', message: '' },
    point: { ret: null, balance: '0' },
    authEcInfo: null,
}

function userReducers(
    state = initialState,
    actions: UserActions
): UserStateType {
    switch (actions.type) {
        case UserActionTypes.setLoading:
            return { ...state, isLoading: actions.payload }
        case UserActionTypes.setProfile:
            return { ...state, me: actions.payload }
        case UserActionTypes.setAuthEcInfo:
            return { ...state, authEcInfo: actions.payload }
        case UserActionTypes.setAuth:
            return { ...state, isAuth: actions.payload }
        case UserActionTypes.setResultAfterTryLogout:
            return { ...state, resultAfterTryLogout: actions.payload }
        case UserActionTypes.setPoints:
            return { ...state, point: actions.payload }
        default:
            return state
    }
}

export default userReducers
