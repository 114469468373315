import jwtDecode from 'jwt-decode'
import { getCookie, removeCookie } from 'typescript-cookie'

export const TOKEN_NAME = 'access_token'
export const COOKIE_ACCESS_TOKEN = 'cms_front_token'
// const TOKEN_EXPIRED_NAME = 'access_token_expired'

interface TokenType {
    id: number
    iat: number
    exp: number
}

export const AuthService = {
    isAuthenticated(): boolean {
        const accessToken = this.getToken()
        return !!accessToken
    },

    getToken(): string {
        let accessToken = getCookie(COOKIE_ACCESS_TOKEN) ?? ''

        if (!accessToken) {
            accessToken = localStorage.getItem(TOKEN_NAME) ?? ''
        }

        return accessToken
    },

    setToken(token: string | null): void {
        if (token) {
            localStorage.setItem(TOKEN_NAME, token)
        }
    },

    isExpired(): boolean {
        const token = this.getToken()
        if (!token) {
            return true
        }
        const { exp } = jwtDecode<TokenType>(token)
        return Date.now() >= exp * 1000
    },

    removeToken(): void {
        localStorage.removeItem(TOKEN_NAME)
        removeCookie(COOKIE_ACCESS_TOKEN)
    },
}
