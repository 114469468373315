import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

type Props = {
    isGlobalLoading?: boolean
}
function Loading(props: Props): JSX.Element {
    const { isGlobalLoading = false } = props

    return (
        <div
            className="page-loading"
            style={
                isGlobalLoading
                    ? {
                          zIndex: 2000,
                          background: 'rgba(255, 255, 255, 0.6)',
                          width: '100%',
                          height: '100%',
                          position: 'fixed',
                          top: 0,
                          left: 0,
                      }
                    : {}
            }
        >
            <div className="page-loading__indicator">
                <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 48 }} />}
                />
            </div>
        </div>
    )
}

Loading.defaultProps = {
    isGlobalLoading: false,
}

export default Loading
