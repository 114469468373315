import * as _ from 'lodash'
import { AxiosError } from 'axios'
import EcService from '../ec.service'
import { formatKeys } from '../../utils/filters'
import { ArtistType } from '../../type/ArtistType'
import { GroupEc } from '../../type/GroupType'
import { GroupFavoriteType } from '../../type/GroupFavoriteType'
import { ArtistFanClubRankListParams } from '../../type/FanClubType'

class GroupsEcService extends EcService {
    public getArtistList(id: number, currentSearchString: string) {
        return new Promise<Array<ArtistType>>((resolve, reject) => {
            this.client
                .get('ArtistListOfGroup/List', {
                    params: {
                        groupId: id,
                        name: currentSearchString,
                    },
                })
                .then((response) => {
                    const artistList = _.map(response.data.data, (artist) => {
                        return formatKeys(artist)
                    })
                    resolve(artistList as Array<ArtistType>)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getGroups(params?: { id: number | string }) {
        return new Promise<Array<GroupEc>>((resolve, reject) => {
            this.client
                .get('ArtistGroup/List', { params })
                .then((response) => {
                    const responseFormatted = response.data.data.map(
                        (entry: GroupEc) => {
                            return formatKeys(entry)
                        }
                    )
                    resolve(responseFormatted)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getFavorites(customerId: number, groupId: number) {
        return new Promise<Array<GroupFavoriteType>>((resolve, reject) => {
            this.client
                .get('FavoriteArtistGroup', {
                    params: {
                        customer_id: customerId,
                        group_id: groupId,
                    },
                })
                .then((response) => {
                    const favorites = _.map(response.data.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(favorites as Array<GroupFavoriteType>)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public addFavorite(customerId: string, groupId: string) {
        const params = new URLSearchParams()
        params.append('customer_id', customerId)
        params.append('group_id', groupId)
        return new Promise<string>((resolve, reject) => {
            this.client
                .post('FavoriteArtistGroup', params)
                .then((response) => {
                    resolve('お気に入り登録をしました')
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public removeFavorite(customerId: string, groupId: string) {
        const data = new URLSearchParams()
        data.append('customer_id', customerId)
        data.append('group_id', groupId)
        return new Promise<string>((resolve, reject) => {
            this.client
                .delete('FavoriteArtistGroup', {
                    data,
                })
                .then((response) => {
                    resolve('お気に入りを削除しました。')
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getShop() {
        return new Promise((resolve, reject) => {
            this.client
                .get('')
                .then((response) => {
                    resolve('お気に入り登録をしました')
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getNft() {
        return new Promise((resolve, reject) => {
            this.client
                .get('')
                .then((response) => {
                    resolve('お気に入り登録をしました')
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getGroupFanClubRankList(
        params: ArtistFanClubRankListParams | null = null
    ) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get('GetArtistFanClubRankList', {
                    params: {
                        artist_id: params?.artistId,
                        artist_group_id: params?.artistGroupId,
                        artist_id_ec: params?.artistIdEc,
                    },
                })
                .then((response) => {
                    resolve(response.data.data as any)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getGroupFanClubList(id: string | number) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get('GetArtistFanClubList', {
                    params: { artist_group_id: id },
                })
                .then((response) => {
                    resolve(response.data.data as any)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public checkFanClubForUser(
        userId: string | number,
        groupId: string | number
    ) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get('CheckFanClubForUser', {
                    params: { user_id: userId, artist_group_id: groupId },
                })
                .then((response) => {
                    resolve(response.data.data as any)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getEventInfo(eventCode: string) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get('GetInfoEvent', {
                    params: {
                        event_code: eventCode,
                    },
                })
                .then((response) => {
                    resolve(response.data as any)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public creatorJoinEvent(param: any) {
        return new Promise<string>((resolve, reject) => {
            this.client
                .post(`CreatorJoinEvent`, param)
                .then((response) => {
                    resolve(response.data as any)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getListRankingCreate(
        eventCode: string,
        sortTime: string,
        pageSize: any,
        limit: any
    ) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get('GetListRankingCreator', {
                    params: {
                        event_code: eventCode,
                        sort_time: sortTime,
                        page: pageSize,
                        limit,
                    },
                })
                .then((response) => {
                    resolve(response.data as any)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getListFanRanking(
        eventCode: string,
        nftId: string,
        sortTime: string,
        pageSize: number,
        limit: number
    ) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get('GetListFanRanking', {
                    params: {
                        event_code: eventCode,
                        nft_id: nftId,
                        sort_time: sortTime,
                        page: pageSize,
                        limit,
                    },
                })
                .then((response) => {
                    resolve(response.data as any)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }
}

export default new GroupsEcService()
