import EcService from '../ec.service'
import { GachaType } from '../../type/GachaType'

class GachaEcService extends EcService {
    public getByIds(gachaIds: string[]) {
        return new Promise<Array<GachaType>>((resolve, reject) => {
            this.client
                .post(`GetGachaData`, {
                    gacha_ids: gachaIds,
                })
                .then((response) => {
                    resolve(response.data.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public createSession(ecGachaId: string, walletId: string) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .post(`CreateGachaSession`, {
                    gacha_pack_id: ecGachaId,
                    auth_id: walletId,
                    redirect_url: window.location.href,
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getTotalBalance(walletId: string) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .post(`GetGachaWallet`, {
                    auth_id: walletId,
                })
                .then((response) => {
                    const balance = Number(response.data.total_balance)
                    resolve(balance)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new GachaEcService()
